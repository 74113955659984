import Navbar from 'react-bootstrap/Navbar';
import { Container, Nav } from 'react-bootstrap';
import { Logo } from 'assets';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  avatar?: string;
  isUser?: boolean;
  logout?: () => void;
  username?: string;
};

export const PublicNavigationBar: React.FC<Props> = () => {
  const [, setShowJoin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setShowJoin(true);
    } else {
      setShowJoin(false);
    }
  }, [location]);

  return (
    <Navbar collapseOnSelect expand={true} bg='#000' variant='dark'>
      <Container>
        <Link to='/'>
          <Navbar.Brand>
            <img src={`${Logo}`} alt='Logo' />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse className='justify-content-end' id='responsive-navbar-nav'>
          <Nav activeKey={window.location.pathname}>
            {/* {!showJoin && (
              <LinkContainer to='/'>
                <Nav.Link>{t('common:menu.Signin')}</Nav.Link>
              </LinkContainer>
            )} */}
            {/* {showJoin && (
              <LinkContainer to='/join'>
                <Nav.Link>{t('common:menu.Signup')}</Nav.Link>
              </LinkContainer>
            )} */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default PublicNavigationBar;
