export const Colors = {
  lightGray: '#EAEAEA',
  gray: '#ccc',
  white: '#FFF',
  black: '#000',
  placeholder: 'rgb(214,214,214)',
  inputLabel: 'rgb(124,124,124)',
  error: '#FFF38A',
  primary: '#00BDFF',
  orange: '#E66752',
  red: '#FF3333',
};
