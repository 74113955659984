import 'services/apis/Interceptors';
import './App.css';
import { Routers } from 'routes/Routers';
import { ToastContainer } from 'react-toastify';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import { Outlet } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
      <ToastContainer />
      <Routers />
      <Outlet />
      <AddToHomeScreen />
    </div>
  );
}

export default App;
