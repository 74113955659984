import { t } from 'i18next';
import { Col, Row } from 'react-bootstrap';

import { Title, SubTitle, Description, StepNumber, StepDescription } from './IntroStyled';

function Intro() {
  return (
    <>
      <Row>
        <Col>
          <Title>{t('common:titles.StoryTellerTitle')}</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubTitle>{t('common:titles.StoryTellerSubTitle')}</SubTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <Description>{t('common:titles.StoryTellerDesciption')}</Description>
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col lg={4} className='mb-3 mt-3'>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Col xs={2} lg={3}>
              <StepNumber>1</StepNumber>
            </Col>
            <Col style={{ marginBottom: 50, maxWidth: 281, minWidth: 'none' }}>
              <StepDescription>{t('common:titles.FirstStep')}</StepDescription>
            </Col>
          </Row>
        </Col>
        <Col lg={4} className='mb-3 mt-3'>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Col xs={2} lg={3}>
              <StepNumber>2</StepNumber>
            </Col>
            <Col style={{ marginBottom: 50, maxWidth: 281, minWidth: 'none' }}>
              <StepDescription>{t('common:titles.SecondStep')}</StepDescription>
            </Col>
          </Row>
        </Col>
        <Col lg={4} className='mb-3 mt-3'>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Col xs={2} lg={3}>
              <StepNumber>3</StepNumber>
            </Col>
            <Col style={{ marginBottom: 50, maxWidth: 281, minWidth: 'none' }}>
              <StepDescription>{t('common:titles.ThirdStep')}</StepDescription>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Intro;
