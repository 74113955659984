import { Container, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { OnboardingService, AwsService } from 'services/apis';
import { t } from 'i18next';
import { FooterContainer } from './indexStyled';

import { ISocial, ISponsors, initModel } from './constants';
import Intro from './intro/Intro';
import Empire from './empire/Empire';
import AboutYourPeople from './aboutYourPeople/AboutYourPeople';
import SponsorsScreen from './sponsors/Sponsors';
import LightsFire from './lightsFire/LightsFire';
import TurnsYouOff from './turnsYouOff/TurnsYouOff';
import { ProgressBarFlexible } from 'components';
// import { deepSetArray } from 'utils/deepSetLib';

const wizardSteps = 6;

const awsService = AwsService.getService();
const onBoarding = OnboardingService.getService();
const uploadContent = async (preview: string) => {
  try {
    const fileBlob = await awsService.getBlob(preview);
    const response = await awsService.getSignatureS3({
      contentType: fileBlob.type,
      preview,
    });
    await awsService.uploadFileToS3SignedUrl({
      signedUrl: response.signedUrl,
      file: fileBlob,
    });
    return response.signedUrl.split('?')[0];
  } catch (error) {
    return Promise.reject(error);
  }
};
const uploadArrayContent = async (items: []) => {
  const data: any[] = [];
  for (let i = 0; i < items.length; i++) {
    const item: any = items[i];
    try {
      const url = await uploadContent(item.preview);
      data.push(url);
    } catch (error: any) {
      console.log(error.message);
    }
  }
  // await Promise.all(
  //   items.map(async (item: any) => {
  //     try {
  //       console.log(item);
  //       const url = await uploadContent(item.preview);
  //       data.push(url);
  //     } catch (error: any) {
  //       console.log(error.message);
  //     }
  //   })
  // );
  return data;
};
function Information() {
  const navigate = useNavigate();
  const routes = useLocation() as any;
  const [isReady, setIsReady] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [wizardStep, setWizardStep] = useState(1);
  const [fields, setFields] = useState({ ...initModel });
  const [errors, setErrors] = useState<any>(JSON.parse(JSON.stringify(initModel)));

  const handlesKeys: ISocial[] = ['socialMedia', 'category', 'handle', 'followers', 'date'];

  const assure_empire = () => {
    let valid = true;

    for (let i = 0; i < fields.socialMediaHandles.length; i++) {
      const handleObject = fields.socialMediaHandles[i];
      const allKeysEmpty = handlesKeys.every(key => !handleObject[key]);

      if (i === 0) {
        valid = valid && handlesKeys.every(key => handleObject[key]);

        if (handleObject.followers) {
          const followers = parseInt(handleObject.followers);
          valid = valid && !isNaN(followers);
        }

        for (const key of handlesKeys) {
          if (!handleObject[key]) {
            setErrors((prevState: any) => ({
              ...prevState,
              socialMediaHandles: [
                {
                  ...prevState.socialMediaHandles[0],
                  [key]: 'Required',
                },
                ...prevState.socialMediaHandles.slice(1),
              ],
            }));
          }
        }
      } else {
        if (allKeysEmpty) {
          setFields(prevState => ({
            ...prevState,
            socialMediaHandles: [
              ...prevState.socialMediaHandles.slice(0, i),
              ...prevState.socialMediaHandles.slice(i + 1),
            ],
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            socialMediaHandles: [
              ...prevState.socialMediaHandles.slice(0, i),
              ...prevState.socialMediaHandles.slice(i + 1),
            ],
          }));
        } else {
          valid = valid && handlesKeys.every(key => handleObject[key]);

          if (handleObject.followers) {
            const followers = parseInt(handleObject.followers);
            valid = valid && !isNaN(followers);
          }

          for (const key of handlesKeys) {
            if (!handleObject[key]) {
              setErrors((prevState: any) => ({
                ...prevState,
                socialMediaHandles: [
                  ...prevState.socialMediaHandles.slice(0, i),
                  {
                    ...prevState.socialMediaHandles[i],
                    [key]: 'Required',
                  },
                  ...prevState.socialMediaHandles.slice(i + 1),
                ],
              }));
            }
          }
        }
      }
    }

    if (!valid) {
      toast.error('All Fields are mandatory');
    }

    return valid;
  };

  const assure_aboutYourPeople = () => {
    let valid = false;
    if (fields.socialMediaHandles.length > 0) {
      valid = true;
    }
    for (let i = 0; i < fields.socialMediaHandles.length; i++) {
      valid = valid && fields.socialMediaHandles[i].images?.length > 0;
    }
    if (!valid) {
      toast.error('Please Upload at Least One Capture For Every Handle');
    }
    return valid;
  };

  const sponcereExcKeys: ISponsors[] = ['name', 'description', 'clause'];
  const sponcerKeys: ISponsors[] = ['name'];

  const assure_sponsors = () => {
    let valid = true;
    for (let i = 0; i < fields.sponsors.length; i++) {
      if (fields.sponsors[i].name && !fields.sponsors[i].type) {
        valid = valid && !!fields.sponsors[i].name;
        for (const key of sponcerKeys) {
          if (!fields.sponsors[i][key]) {
            setErrors((prevState: any) => {
              const updatedErrors = {
                ...prevState,
                sponsors: [
                  ...prevState.sponsors.slice(0, i),
                  {
                    ...prevState.sponsors[i],
                    [key]: 'Required',
                  },
                  ...prevState.sponsors.slice(i + 1),
                ],
              };
              return updatedErrors;
            });
          }
        }
      } else if (!fields.sponsors[i].name && !fields.sponsors[i].type) {
        for (const key of sponcerKeys) {
          if (!fields.sponsors[i][key]) {
            setErrors((prevState: any) => {
              const updatedErrors = {
                ...prevState,
                sponsors: [
                  ...prevState.sponsors.slice(0, i),
                  {
                    ...prevState.sponsors[i],
                    [key]: '',
                  },
                  ...prevState.sponsors.slice(i + 1),
                ],
              };
              return updatedErrors;
            });
          }
        }
      } else if (fields.sponsors[i].type === true) {
        valid =
          valid &&
          !!fields.sponsors[i].name &&
          !!fields.sponsors[i].description &&
          !!fields.sponsors[i].clause;

        for (const key of sponcereExcKeys) {
          if (!fields.sponsors[i][key]) {
            setErrors((prevState: any) => {
              const updatedErrors = {
                ...prevState,
                sponsors: [
                  ...prevState.sponsors.slice(0, i),
                  {
                    ...prevState.sponsors[i],
                    [key]: 'Required',
                  },
                  ...prevState.sponsors.slice(i + 1),
                ],
              };
              return updatedErrors;
            });
          }
        }
      } else {
        for (const key of sponcereExcKeys) {
          if (!fields.sponsors[i][key]) {
            setErrors((prevState: any) => {
              const updatedErrors = {
                ...prevState,
                sponsors: [
                  ...prevState.sponsors.slice(0, i),
                  {
                    ...prevState.sponsors[i],
                    [key]: '',
                  },
                  ...prevState.sponsors.slice(i + 1),
                ],
              };
              return updatedErrors;
            });
          }
        }
      }
    }
    return valid;
  };
  const assure_turnsYouOff = () => {
    let valid = false;
    if (fields.turnsYouOff.length > 0) {
      valid = true;
    }
    for (let i = 0; i < fields.turnsYouOff.length; i++) {
      valid = valid && !!fields.turnsYouOff[i].title && !!fields.turnsYouOff[i].detail;
    }
    if (!valid) {
      toast.error('Please Select a Minimum One Card');
    }
    return valid;
  };
  const assure_lightsYourFire = () => {
    let valid = false;
    if (fields.lightsYourFire.length > 0) {
      valid = true;
    }
    for (let i = 0; i < fields.lightsYourFire.length; i++) {
      valid = valid && !!fields.lightsYourFire[i].title && !!fields.lightsYourFire[i].detail;
    }
    if (!valid) {
      toast.error('Please Select a Minimum One Card');
    }
    return valid;
  };
  const handleBack = () => {
    setWizardStep(wizardStep - 1);
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    let valid = false;
    if (wizardStep === 6) {
      valid = assure_turnsYouOff();
    }
    if (valid) {
      try {
        const data = {
          lightsYourFire: fields.lightsYourFire,
          turnsYouOff: fields.turnsYouOff,
          sponsors: [],
          presentation: undefined,
          socialMediaHandles: [],
        };
        // @ts-ignore
        if (fields.presentation && fields.presentation.preview) {
          // @ts-ignore
          data.presentation = await uploadContent(fields.presentation.preview);
        }
        for (let i = 0; i < fields.socialMediaHandles.length; i++) {
          const item: any = fields.socialMediaHandles[i];
          try {
            const followers = parseInt(item.followers);
            const temp = {
              socialMedia: item.socialMedia.toLowerCase(),
              category: item.category.toLowerCase(),
              handle: item.handle.toLowerCase(),
              followers: isNaN(followers) ? 0 : followers,
              date: item.date,
              images: await uploadArrayContent(item.images),
            };
            // @ts-ignore
            data.socialMediaHandles.push(temp);
          } catch (error: any) {
            console.log(error.message);
          }
        }

        // await Promise.all(
        //   fields.socialMediaHandles.map(async (item: any) => {
        //     try {
        //       const followers = parseInt(item.followers);
        //       const temp = {
        //         socialMedia: item.socialMedia.toLowerCase(),
        //         category: item.category.toLowerCase(),
        //         handle: item.handle.toLowerCase(),
        //         followers: isNaN(followers) ? 0 : followers,
        //         date: item.date,
        //         images: await uploadArrayContent(item.images),
        //       };
        //       // @ts-ignore
        //       data.socialMediaHandles.push(temp);
        //     } catch (error: any) {
        //       console.log(error.message);
        //     }
        //   })
        // );
        fields.sponsors.map(async (item: any) => {
          const temp = {
            name: item.name || '',
            relation: {
              type: item.type ? 'exclusive' : 'notExclusive',
              description: item.description || '',
              clause: item.clause || '',
            },
          };
          // @ts-ignore
          data.sponsors.push(temp);
        });
        const socialMediaHandles: any = await onBoarding.updateRecord(data);
        if (
          socialMediaHandles.length > 0 &&
          socialMediaHandles.length !== data.socialMediaHandles.length
        ) {
          const copyOf = JSON.parse(JSON.stringify(socialMediaHandles));
          const verified: any[] = data?.socialMediaHandles.reduce((acc: any, social: any) => {
            const isIn = socialMediaHandles.findIndex(
              (sm: any) =>
                sm.handle === social.handle &&
                social.socialMedia === sm.socialMedia &&
                social.followers === sm.followers
            );
            if (isIn > -1) {
              socialMediaHandles.splice(isIn, 1);
            } else {
              acc.push(social);
            }
            return acc;
          }, []);
          navigate('/handle-conflict', {
            state: {
              conflictedHandles: copyOf,
              verifiedHandles: verified,
              itemId: routes.state?.itemId,
              membership: routes.state?.membership,
              filteredData: { ...data, socialMediaHandles: verified },
            },
          });
          return;
        }
        if (data.socialMediaHandles.length === socialMediaHandles.length) {
          setWizardStep(2);
          return;
        }
        navigate('/storyteller', {
          state: { membership: routes.state?.membership, itemId: routes.state?.itemId },
        });
      } catch (error: any) {
        setIsSubmit(false);
        toast.error(error.message);
        if (error.message === 'All handles has been rejected.') {
          setWizardStep(2);
        }
      }
    } else {
      setIsSubmit(false);
    }
  };
  const handleNextStep = () => {
    let valid = true;
    if (wizardStep === 2) {
      valid = assure_empire();
    } else if (wizardStep === 3) {
      valid = assure_aboutYourPeople();
    } else if (wizardStep === 4) {
      valid = assure_sponsors();
    } else if (wizardStep === 5) {
      valid = assure_lightsYourFire();
    } else if (wizardStep === 6) {
      valid = assure_turnsYouOff();
    }
    if (valid) {
      setWizardStep(wizardStep + 1);
    }
  };

  const renderContent = useMemo(() => {
    switch (wizardStep) {
      case 1:
        return <Intro />;
      case 2:
        return (
          <Empire inputs={fields} setFields={setFields} errors={errors} setErrors={setErrors} />
        );
      case 3:
        return <AboutYourPeople inputs={fields} setFields={setFields} />;
      case 4:
        return (
          <SponsorsScreen
            inputs={fields}
            setFields={setFields}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 5:
        return <LightsFire inputs={fields} setFields={setFields} />;
      case 6:
        return <TurnsYouOff inputs={fields} setFields={setFields} />;
      default:
        break;
    }
  }, [wizardStep, fields, errors]);

  useEffect(() => {
    (async function () {
      setIsReady(false);
      try {
        if (routes.state?.membership !== 'influencer') {
          navigate('/home', { replace: true });
        }
        const status = await onBoarding.checkStatus();
        if (status !== 'new') {
          navigate('/home', { replace: true });
        } else {
          setFields({ ...initModel });
          setIsReady(true);
        }
      } catch (error: any) {
        toast.error(error.message);
        setIsReady(true);
      }
    })();
  }, []);

  return (
    <Container className='text-white'>
      {isReady && (
        <>
          <div style={{ marginBottom: 110 }}>{renderContent}</div>
          <FooterContainer style={{ display: 'block', width: '100%' }}>
            <ProgressBarFlexible valuePercentage={((wizardStep - 1) * 100) / wizardSteps} />
            <div className='mt-3 text-center'>
              {wizardStep === 1 && (
                <Button variant='info' onClick={handleNextStep}>
                  {t('common:buttons.GetStarted')}
                </Button>
              )}
              {wizardStep !== 1 && (
                <Button
                  style={{ width: 130, marginRight: 15 }}
                  className='shadow-none'
                  variant='outline-info'
                  onClick={handleBack}
                >
                  {t('common:buttons.Back')}
                </Button>
              )}
              {wizardStep !== 1 && wizardStep !== wizardSteps && (
                <Button
                  style={{ width: 110 }}
                  variant='info'
                  className='shadow-none'
                  onClick={handleNextStep}
                >
                  {t('common:buttons.Next')}
                </Button>
              )}
              {wizardStep === 6 && (
                <Button
                  style={{ width: 110 }}
                  disabled={isSubmit}
                  variant='info'
                  className='shadow-none'
                  onClick={handleSubmit}
                >
                  {t('common:buttons.Next')}
                </Button>
              )}
            </div>
          </FooterContainer>
        </>
      )}
    </Container>
  );
}

export default Information;
