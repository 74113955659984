import { t } from 'i18next';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import { OauthService } from 'services/apis';

import {
  LogInByMobileTitle,
  FormHeaderDescription,
  LoginFormBackground,
} from './LoginByPhoneStyled';
import { FormProvider, useForm } from 'react-hook-form';
import { LoginByPhoneScreenTypes } from '../types';
import { FormTextField } from 'components';
import {
  FormHeader,
  FormHeaderTitle,
  PhoneContainer,
  SubMainTitle,
} from '../joinByMobile/JoinStyled';

const oauthService = OauthService.getService();

export const LoginByPhoneScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const formMethods = useForm<LoginByPhoneScreenTypes>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      phone_key: '1',
    },
  });
  const onSubmit = async (data: LoginByPhoneScreenTypes) => {
    try {
      setIsLoading(true);
      const concatNumber = `${data.phone_key}${data.phone}`;
      await oauthService.loginByPhone(concatNumber);
      setIsLoading(false);
      localStorage.setItem('concatNumber', concatNumber);
      navigate('/verification', { state: concatNumber });
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Container className='text-white'>
      <Row>
        <Col>
          <LogInByMobileTitle>{t('common:titles.goodToSeeYou')}</LogInByMobileTitle>
          <SubMainTitle>{t('common:titles.WelcomeBack')}</SubMainTitle>
        </Col>
        <Col lg={5} style={{ paddingLeft: '0', paddingRight: '0' }}>
          <LoginFormBackground>
            <FormHeader>
              <div style={{ height: 33 }} />
              <FormHeaderTitle> {t('common:titles.EnterYourPhone')}</FormHeaderTitle>
            </FormHeader>
            <FormHeaderDescription style={{ marginTop: 10, marginBottom: 32 }}>
              {t('common:titles.EnterPhoneDescription')}
              <br />
              <span>Don’t have an account? </span>
              <Link style={{ fontSize: 14, color: '#fff', fontWeight: 'bold' }} to={'/join'}>
                Create one now
              </Link>
            </FormHeaderDescription>
            <Row>
              <Col>
                <FormProvider {...formMethods}>
                  <Form style={{ display: 'block' }}>
                    <PhoneContainer>
                      <FormTextField
                        isPhone
                        name='phone_key'
                        rules={{
                          required: true,
                        }}
                        style={{ marginTop: 9 }}
                      />
                      <FormTextField
                        name='phone'
                        isNumber
                        placeholder='* Phone Number'
                        placeholderTextColor='#fff'
                        rules={{
                          required: true,
                        }}
                        message={'Required'}
                      />
                    </PhoneContainer>
                  </Form>
                </FormProvider>
              </Col>
            </Row>
            <Row className='text-center' style={{ marginTop: 48 }}>
              <Col>
                <Button
                  size='lg'
                  variant='info'
                  disabled={isLoading}
                  onClick={formMethods.handleSubmit(onSubmit)}
                  className='text-white'
                  style={{
                    width: 200,
                    borderWidth: 2,
                    borderColor: '#fff',
                    borderStyle: 'solid',
                  }}
                >
                  {t('common:buttons.ImMe')}
                </Button>
              </Col>
            </Row>
          </LoginFormBackground>
        </Col>
      </Row>
    </Container>
  );
};
