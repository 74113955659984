import axios from 'axios';
import LocalStorageService from './LocalStorageService.js';
import { response_catch } from './commonResponse';

const localStorageService = LocalStorageService.getService();
/**
 * add common response
 */
const OauthService = (function () {
  let _service = null;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _login(email, password) {
    if (email.indexOf('@') !== -1) {
      email = email.toLowerCase();
    }
    return axios
      .post('/oauth/token', {
        username: email,
        password: password,
        grant_type: 'password',
      })
      .then(async res => {
        if (res && res.data && res.data.access_token) {
          localStorageService.setToken(res.data);
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(new Error('Unable to login'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to login');
      });
  }

  function _loginByPhone(phone) {
    return axios
      .post(
        '/oauth/token/phone',
        {
          phone: phone,
        },
        {
          headers: { skip_access_token: true },
        }
      )
      .then(async res => {
        if (res && res.data) {
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(new Error('Unable to login'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to login');
      });
  }

  function _loginByPhoneValidate(code, phone) {
    return axios
      .post(
        '/oauth/token/phone/code',
        {
          code: code,
          phone: phone,
        },
        {
          headers: { skip_access_token: true },
        }
      )
      .then(async res => {
        if (res && res.data && res.data.data && res.data.data.access_token) {
          localStorageService.setToken(res.data.data);
          return Promise.resolve(res.data.data);
        } else {
          return Promise.reject(new Error('Unable to login'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to login');
      });
  }

  async function _logout() {
    const refresh_token = localStorageService.getRefreshToken();
    const access_token = localStorageService.getAccessToken();

    if (refresh_token) {
      return axios
        .delete('/oauth/refreshToken/' + refresh_token)
        .then(() => {
          if (access_token) {
            return axios.delete('/oauth/accessToken/' + access_token);
          }
        })
        .catch(() => {
          if (access_token) {
            return axios.delete('/oauth/accessToken/' + access_token);
          }
        });
    } else {
      if (access_token) {
        return axios.delete('/oauth/accessToken/' + access_token);
      }
    }
  }

  return {
    getService: _getService,
    login: _login,
    logout: _logout,
    loginByPhone: _loginByPhone,
    loginByPhoneValidate: _loginByPhoneValidate,
  };
})();
export default OauthService;
