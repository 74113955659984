import styled, { css } from 'styled-components';
import { Container } from 'react-bootstrap';
import { Colors } from 'theme/Colors';

export const HomeContainer = styled(Container)`
  height: 100vh;
`;
export const Title = styled.h1`
  font-size: 46px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: DIN-Condensed;
  line-height: 46px;
  @media (max-width: 768px) {
    font-size: 40px;
  }
  color: ${Colors.white};
`;
export const Description = styled.span`
  font-size: 16px;
  line-height: 140%;
  color: ${Colors.white};
  ${css`
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `}
`;
export const SubTitle = styled.span`
  font-size: 89px;
  color: ${Colors.white};
  font-weight: bold;
  text-transform: uppercase;
  font-family: DIN-Condensed;
  line-height: 124.6px;
  ${css`
    @media (max-width: 991px) {
      font-size: 90px;
    }
    @media (max-width: 768px) {
      font-size: 80px;
      line-height: 80px;
    }
  `}
  margin-bottom: 31px;
`;
export const Divider = styled.div`
  height: 1px;
  background-color: ${Colors.white};
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
`;
export const UserType = styled.span`
  font-size: 32px;
  color: ${Colors.white};
  font-weight: bold;
  font-family: DIN-Condensed;
  line-height: 27.2px;
  ${css`
    @media (max-width: 991px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  `}
`;
export const Invited = styled.span`
  font-size: 16px;
  color: ${Colors.white};
  font-family: open sans;
  line-height: 22.4px;
`;
export const Status = styled.span<{ status: string }>`
  color: ${props =>
    props.status === 'new'
      ? Colors.primary
      : props.status === 'signed' || props.status === 'accepted'
      ? '#52FF00'
      : props.status === 'notSigned'
      ? Colors.red
      : '#FF3333'};
  font-size: 18px;
`;
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const Button = styled.button<{
  bgColor?: string;
  width?: number;
  height?: number;
  lineHeight?: number;
}>`
  color: ${Colors.white};
  background-color: ${props => props.bgColor || Colors.primary};
  border-radius: 3px;
  border: 3px solid ${Colors.white};
  line-height: ${props => props.lineHeight ?? 27}px;
  height: ${props => props.height || 48}px;
  width: ${props => `${props.width || 159}px`};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  padding-inline: 15px;
  ${css`
    @media (max-width: 576px) {
      width: 100%;
    }
  `}
`;

export const TextBold = styled.div`
  color: ${Colors.white};
  font-weight: bold;
  font-size: 16px;
`;

export const Input = styled.input`
  color: ${Colors.white};
  border-color: transparent;
  background-color: transparent;
  outline: none;
  padding-block: 8px;
  font-size: 16px;
  border-bottom: 1px solid ${Colors.white};
`;
