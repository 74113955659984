import { AwsService } from 'services/apis';

const awsService = AwsService.getService();

const deleteFromS3 = async (imagePath: string | undefined): Promise<boolean> => {
  try {
    if (imagePath?.includes('amazonaws.com/')) {
      const filePath = imagePath?.split('amazonaws.com/')[1];
      await awsService.deleteS3({ filePath });
    }
    return Promise.resolve(true);
  } catch (error: any) {
    console.log('ERROR:', error.message);
    return Promise.resolve(false);
  }
};

export default deleteFromS3;
