import { FormTextField } from 'components';
import { DeleteButton, Divider } from 'components/shared';
import { getKeys } from 'helpers/getKeys';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { OnboardingService } from 'services/apis';
import {
  CustomInput,
  CustomSelectOption,
  CustomSelectValue,
} from 'views/onBoarding/influencer/information/empire/EmpireForm';
import { EmpireTypeProp } from 'views/urac/types';
import {
  MainTitle,
  EmpireDescription,
  EmpireAsOfWrapper,
  FieldsContainer,
  LabelContainer,
} from './EmpireStyled';
import { deepSetArray } from 'utils/deepSetLib';

const onBoarding = OnboardingService.getService();

function Empire({ inputs, setFields, errors, setErrors }: any) {
  const [getSocial, setSocial] = useState([]);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [isPhoneDelete, setIsPhoneDelete] = useState(false);

  const formMethods = useForm<EmpireTypeProp>({
    mode: 'onChange',
    defaultValues: {
      socialMediaHandles: inputs.socialMediaHandles.map((socialMedia: any) => ({
        socialMedia: socialMedia.socialMedia,
        category: socialMedia.category,
        followers: socialMedia.followers,
        date: socialMedia.date,
        handle: socialMedia.handle,
      })),
    },
  });

  const { fields, append, remove, update } = useFieldArray<EmpireTypeProp>({
    name: 'socialMediaHandles',
    control: formMethods.control,
  });

  const socialField = useWatch({
    control: formMethods.control,
    name: 'socialMediaHandles',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields]);

  useEffect(() => {
    if (
      getKeys(socialField![socialField!.length - 1]).some(
        key => !!socialField![socialField!.length - 1][key]
      ) &&
      socialField?.length &&
      fields?.length <= socialField?.length
    ) {
      append({});
    }
  }, [socialField]);

  useEffect(() => {
    (async function getSocial() {
      const social = await onBoarding.getSocialOptions();
      const category = await onBoarding.getCategories();
      setSocial(social);
      setCategoryOption(category);
    })();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsPhoneDelete(false);
      } else if (window.innerWidth < 768) {
        setIsPhoneDelete(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const updateValue = ({ name, value }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [h, index, name2] = name?.split('.') || '';
    if (name2) {
      update(index, {
        ...fields?.[index],
        [name2]: value,
      });
    }
  };
  const onFieldChange = useCallback(
    (event: any) => {
      const { value, name } = event.target;
      updateValue({ value, name });
      setFields((prevState: any) => {
        return deepSetArray(prevState, name, value);
      });
      if (value) {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, '');
        });
      } else {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, 'Required');
        });
      }
    },
    [setFields, fields, errors]
  );
  const onSelectChange = useCallback(
    ({ item, name }: any) => {
      updateValue({ value: item?.value, name });
      setFields((prevState: any) => {
        return deepSetArray(prevState, name, item.value);
      });
      if (item?.value) {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, '');
        });
      } else {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, 'Required');
        });
      }
    },
    [setFields, fields, errors]
  );

  const onFollowersChange = useCallback(
    ({ index, value }: any) => {
      if (/^\d+$/.test(value.replaceAll(/\D/g, '')) || !value) {
        const newFields = [...fields];
        const newValue = {
          ...fields?.[index],
          followers: value ? value.replaceAll(/\D/g, '') * 1 : 0,
        };

        update(index, newValue);
        newFields[index] = newValue;

        setFields((prevState: any) => {
          return deepSetArray(
            prevState,
            `socialMediaHandles.${index}.followers`,
            value ? value.replaceAll(/\D/g, '') * 1 : 0
          );
        });
        if (value) {
          setErrors((prevState: any) => {
            return deepSetArray(prevState, `socialMediaHandles.${index}.followers`, '');
          });
        } else {
          setErrors((prevState: any) => {
            return deepSetArray(prevState, `socialMediaHandles.${index}.followers`, 'Required');
          });
        }
      }
    },
    [setFields, fields, errors]
  );
  const onDateChange = useCallback(
    ({ date, name }: any) => {
      const time = date?.getTime() || null;
      if (!isNaN(time)) {
        updateValue({ value: time, name });
        setFields((prevState: any) => {
          if (!isNaN(time)) {
            return deepSetArray(prevState, name, time);
          }
        });
      }
      if (time) {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, '');
        });
      } else {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, 'Required');
        });
      }
    },
    [setFields, fields]
  );
  const onDeleteFields = useCallback(
    (index: any) => {
      setFields((prevState: any) => {
        if (prevState.socialMediaHandles.length == 1) {
          prevState?.socialMediaHandles?.splice(index, 1, {});
          return prevState;
        }
        prevState?.socialMediaHandles?.splice(index, 1);
        return prevState;
      });
      setErrors((prevState: any) => {
        const updatedErrors = {
          ...prevState,
          socialMediaHandles: [
            ...prevState.socialMediaHandles.slice(0, index),
            ...prevState.socialMediaHandles.slice(index + 1),
          ],
        };
        return updatedErrors;
      });
      remove(index);
    },
    [setFields]
  );

  return (
    <div>
      <Row className='text-center'>
        <Col>
          <MainTitle>{t('common:titles.EmpireMainTitle')}</MainTitle>
        </Col>
      </Row>
      <Row className='text-center'>
        <Col>
          <EmpireDescription>{t('common:titles.EmpireDescription')}</EmpireDescription>
        </Col>
      </Row>
      <Row className='mt-5'>
        <FormProvider {...formMethods}>
          <Form>
            {fields.map((item, i) => (
              <div key={item.id}>
                <FieldsContainer>
                  <div style={{ position: 'relative', paddingBottom: 11 }}>
                    <LabelContainer>{t('common:inputs.SocialMedia')}</LabelContainer>
                    <FormTextField
                      name={`socialMediaHandles.${i}.socialMedia`}
                      select
                      placeholder={`${t('common:inputs.SocialMedia')} *`}
                      rules={{
                        required: true,
                      }}
                      onFieldChange={(item: any) =>
                        onSelectChange({ item, name: `socialMediaHandles.${i}.socialMedia` })
                      }
                      value={getSocial.find((social: any) => social.value === item.socialMedia)}
                      message={'required'}
                      innerRef={formMethods.register}
                      options={getSocial}
                      components={{
                        Option: CustomSelectOption,
                        SingleValue: CustomSelectValue,
                      }}
                      ErrorBorder={
                        errors?.socialMediaHandles?.[i] &&
                        errors?.socialMediaHandles?.[i].socialMedia === 'Required'
                          ? '#FFF38A'
                          : null
                      }
                    />
                    {errors?.socialMediaHandles?.[i] &&
                      errors?.socialMediaHandles?.[i].socialMedia === 'Required' && (
                        <span
                          style={{
                            position: 'absolute',
                            bottom: -14,
                            left: 20,
                            color: '#FFF38A',
                            fontSize: 12,
                          }}
                        >
                          {errors?.socialMediaHandles?.[i].socialMedia}
                        </span>
                      )}
                  </div>
                  <div style={{ position: 'relative', paddingBottom: 10 }}>
                    <LabelContainer>{t('common:inputs.Handle')}</LabelContainer>

                    <FormTextField
                      name={`socialMediaHandles.${i}.handle`}
                      placeholder={`${t('common:inputs.Handle')} *`}
                      onFieldChange={onFieldChange}
                      rules={{
                        required: true,
                      }}
                      style={{
                        paddingBlock: 7,
                        position: 'relative',
                        borderBottomColor:
                          errors?.socialMediaHandles?.[i] &&
                          errors?.socialMediaHandles?.[i].handle === 'Required' &&
                          '#FFF38A',
                      }}
                      message={'Required'}
                    />
                    {errors?.socialMediaHandles?.[i] &&
                      errors?.socialMediaHandles?.[i].handle === 'Required' && (
                        <span
                          style={{
                            position: 'absolute',
                            bottom: -14,
                            color: '#FFF38A',
                            fontSize: 12,
                          }}
                        >
                          {errors?.socialMediaHandles?.[i].handle}
                        </span>
                      )}
                  </div>
                  <div style={{ position: 'relative', paddingBottom: 11 }}>
                    <LabelContainer>{t('common:inputs.Category')}</LabelContainer>

                    <FormTextField
                      name={`socialMediaHandles.${i}.category`}
                      select
                      message={'required'}
                      onFieldChange={(item: any) =>
                        onSelectChange({ item, name: `socialMediaHandles.${i}.category` })
                      }
                      placeholder={`${t('common:inputs.Category')} *`}
                      rules={{
                        required: false,
                      }}
                      value={CategoryOption.find(
                        (category: any) => category.value === item.category
                      )}
                      options={CategoryOption}
                      innerRef={formMethods.register}
                      components={{
                        Option: CustomSelectOption,
                        SingleValue: CustomSelectValue,
                      }}
                      ErrorBorder={
                        errors?.socialMediaHandles?.[i] &&
                        errors?.socialMediaHandles?.[i].category === 'Required'
                          ? '#FFF38A'
                          : null
                      }
                    />
                    {errors?.socialMediaHandles?.[i] &&
                      errors?.socialMediaHandles?.[i].category === 'Required' && (
                        <span
                          style={{
                            position: 'absolute',
                            bottom: -14,
                            left: 20,
                            color: '#FFF38A',
                            fontSize: 12,
                          }}
                        >
                          {errors?.socialMediaHandles?.[i].category}
                        </span>
                      )}
                  </div>
                  <div style={{ position: 'relative', paddingBottom: 10 }}>
                    <LabelContainer>Followers</LabelContainer>

                    <FormTextField
                      name={`socialMediaHandles.${i}.followers`}
                      placeholder={`${t('common:inputs.Followers')} *`}
                      onFieldChange={(e: any) => {
                        const isOnlyNumbers = /^[0-9,]+$/.test(e.target.value);
                        if (!isOnlyNumbers && e.target.value) {
                          setErrors((prevState: any) => {
                            return deepSetArray(
                              prevState,
                              `socialMediaHandles.${i}.followers`,
                              'Invalid Format'
                            );
                          });
                        } else {
                          setErrors((prevState: any) => {
                            return deepSetArray(prevState, `socialMediaHandles.${i}.followers`, '');
                          });
                        }
                        onFollowersChange({
                          index: i,
                          value: e.target.value,
                        });
                      }}
                      rules={{
                        required: false,
                      }}
                      value={
                        isNaN(item.followers)
                          ? ''
                          : item.followers
                          ? (+item.followers)?.toLocaleString()
                          : item.followers
                      }
                      style={{
                        paddingBlock: 7,
                        position: 'relative',
                        borderBottomColor:
                          errors?.socialMediaHandles?.[i] &&
                          (errors?.socialMediaHandles?.[i].followers === 'Required' ||
                            errors?.socialMediaHandles?.[i].followers === 'Invalid Format') &&
                          '#FFF38A',
                      }}
                      message={'required'}
                    />
                    {errors?.socialMediaHandles?.[i] &&
                      (errors?.socialMediaHandles?.[i].followers === 'Required' ||
                        errors?.socialMediaHandles?.[i].followers === 'Invalid Format') && (
                        <span
                          style={{
                            position: 'absolute',
                            bottom: -14,
                            color: '#FFF38A',
                            fontSize: 12,
                          }}
                        >
                          {errors?.socialMediaHandles?.[i].followers}
                        </span>
                      )}
                  </div>
                  <div style={{ position: 'relative', paddingBottom: 16 }}>
                    <EmpireAsOfWrapper>
                      <span
                        style={{
                          fontSize: 18,
                          color: 'white',
                          width: 50,
                          paddingBottom: 7,
                          flex: '1 1 auto',
                        }}
                      >
                        {t('common:titles.AsOf')}
                      </span>
                      <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                        <LabelContainer style={{ marginBottom: 9 }}>
                          {t('common:inputs.Date')}
                        </LabelContainer>
                        <FormTextField
                          name={`socialMediaHandles.${i}.date`}
                          isDate
                          onFieldChange={(date: any) =>
                            onDateChange({ date, name: `socialMediaHandles.${i}.date` })
                          }
                          maxDate={new Date()}
                          format={'MM/DD/YYYY'}
                          render={<CustomInput />}
                          rules={{
                            required: false,
                          }}
                          ErrorBorder={
                            errors?.socialMediaHandles?.[i] &&
                            errors?.socialMediaHandles?.[i].date === 'Required'
                              ? '#FFF38A'
                              : null
                          }
                        />
                        {errors?.socialMediaHandles?.[i] &&
                          errors?.socialMediaHandles?.[i].date === 'Required' && (
                            <span
                              style={{
                                position: 'absolute',
                                bottom: -14,
                                color: '#FFF38A',
                                fontSize: 12,
                              }}
                            >
                              {errors?.socialMediaHandles?.[i].date}
                            </span>
                          )}
                      </div>
                      {!isPhoneDelete && (
                        <div>
                          {i >= 0 && (
                            <div style={{ paddingLeft: 20, paddingBottom: 12 }}>
                              <DeleteButton
                                onClick={() => (fields.length - 1 ? onDeleteFields(i) : null)}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </EmpireAsOfWrapper>
                  </div>
                </FieldsContainer>
                {isPhoneDelete && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {i >= 0 && (
                      <div style={{ paddingLeft: 20 }}>
                        <DeleteButton
                          onClick={() => (fields.length - 1 ? onDeleteFields(i) : null)}
                        />
                      </div>
                    )}
                  </div>
                )}
                {fields.length > 1 ? <Divider /> : null}
              </div>
            ))}
          </Form>
        </FormProvider>
      </Row>
    </div>
  );
}

export default Empire;
