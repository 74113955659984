import axios from 'axios';
import LocalStorageService from './LocalStorageService';
import { response_catch, response_then } from './commonResponse';

const localStorageService = LocalStorageService.getService();

const UracService = (function () {
  let _service = null;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  async function _getUser() {
    // const user = email || (await localStorageService.getUsername());
    // if (user) {
    return axios
      .get('/urac/user/me')
      .then(res => {
        if (res && res.data && res.data.result) {
          localStorageService.setUserid(res.data.data._id);
          // return Promise.resolve(res.data.data);
          // } else {
          //   return Promise.reject(new Error('Unable to get user information'));
        }
        return response_then(res, 'Unable to get user information');
      })
      .catch(error => {
        return response_catch(error, 'Unable to get user information');
        // return Promise.reject(new Error('Unable to get user information'));
      });
    // } else {
    //   return Promise.reject(new Error('Unable to get user information'));
    // }
  }

  async function _updateMyProfile(fields, profile) {
    const userid = localStorageService.getUserid();
    if (userid) {
      let record = {
        id: userid,
        firstName: fields.firstName,
        lastName: fields.lastName,
      };
      if (profile) {
        record.profile = profile;
      }
      return axios
        .put('/urac/account', record)
        .then(res => {
          return response_then(res, 'Unable to update profile information');
        })
        .catch(error => {
          return response_catch(error, 'Unable to update profile information');
        });
    } else {
      return Promise.reject(new Error('Unable to update profile information'));
    }
  }

  function _validateJoin(token) {
    return axios
      .get('/urac/validate/join?token=' + token)
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(true);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _validateInvite(fields) {
    return axios
      .put('/onboarding/validate/invite/' + fields.code, {
        email: fields.email,
        firstName: fields.firstName,
        lastName: fields.lastName,
        phone: fields.phone,
      })
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(true);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _joinUser(fields) {
    return axios
      .post('/urac/join/code', {
        firstName: fields.firstName,
        lastName: fields.lastName,
        email: fields.email.toLowerCase(),
        phone: fields.phone,
      })
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(true);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.codes &&
          error.response.data.errors.codes.includes(402)
        ) {
          return Promise.reject(new Error('Username and/or Email already exist.'));
        } else {
          return Promise.reject(new Error('Unable to sign up'));
        }
      });
  }
  function _getUserACL(extKey) {
    //TODO: check cache
    let cacheIndex = '0000000000000000';
    if (extKey) {
      cacheIndex = extKey.substr(extKey.length - 16);
    }
    let acl_cached = localStorageService.getUserKeyACL(cacheIndex);
    if (acl_cached) {
      return Promise.resolve(acl_cached);
    }
    let opt = null;
    if (extKey) {
      opt = {
        headers: { key: extKey },
      };
    }
    return axios
      .get('/soajs/acl', opt)
      .then(res => {
        if (res && res.data) {
          //TODO: cache
          localStorageService.setUserKeyACL(res.data, cacheIndex);
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(new Error('Unable to get ACL please try again later'));
        }
      })
      .catch(() => {
        return Promise.reject(new Error('Unable to get ACL please try again later'));
      });
  }

  return {
    getService: _getService,
    getUser: _getUser,
    updateMyProfile: _updateMyProfile,
    validateJoin: _validateJoin,
    joinUser: _joinUser,
    getUserACL: _getUserACL,
    validateInvite: _validateInvite,
  };
})();
export default UracService;
