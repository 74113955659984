import { Colors } from 'theme/Colors';
import styled, { css } from 'styled-components';

export const LetsSignTitle = styled.p`
  font-size: 99px;
  line-height: 85%;
  color: ${Colors.white};
  font-weight: bold;
  max-width: none;
  text-align: center;
  font-family: DIN-Condensed;
`;
export const LetsSignDescription = styled.p`
  font-size: 21px;
  color: ${Colors.white};
  max-width: none;
  text-align: center;
  text-align: left;
  width: 635px;
  ${css`
    @media (max-width: 768px) {
      width: 100%;
    }
  `}
`;
