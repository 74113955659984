import { t } from 'i18next';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import { FormTextField, ResendButton } from 'components';
import { useAbility, useAuthorization } from 'services';
import LocalStorageService, {
  AbilitiesService,
  OnboardingService,
  UracService,
} from 'services/apis';

import { ValidateJoinTypeProp } from '../types';
import {
  MainTitle,
  FormBackground,
  FormHeader,
  FormHeaderDescription,
  FormHeaderTitle,
} from './VerificationJoinStyled';

const abilitiesService = AbilitiesService.getService();
const localStorageService = LocalStorageService.getService();
const uracService = UracService.getService();
const onBoarding = OnboardingService.getService();

function VerificationJoinScreen() {
  const { ability } = useAbility();
  const { signIn } = useAuthorization();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const router = useLocation() as any;
  const code = router.state?.code;
  const formMethods = useForm<ValidateJoinTypeProp>({
    mode: 'onChange',
    defaultValues: {
      smsCode: '',
      emailCode: '',
    },
  });

  const onSubmit = async (data: ValidateJoinTypeProp) => {
    const fields = { smsCode: data.smsCode, emailCode: data.emailCode, code };
    try {
      setIsLoading(true);
      await onBoarding.validateCodes(fields);
      localStorageService.clearUserKeyACL();
      const user = await uracService.getUser();
      await abilitiesService.createUserAbilities(ability);
      signIn(user);
      setIsLoading(false);
      navigate('/home', { replace: true });
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
      formMethods.reset();
    }
  };

  return (
    <Container className='text-white'>
      <Row>
        <Col>
          <MainTitle>
            Prove
            <br /> You&apos;re You
          </MainTitle>
        </Col>
        <Col lg={5} style={{ paddingLeft: '0', paddingRight: '0' }}>
          <FormBackground>
            <FormHeader>
              <FormHeaderTitle>{t('common:titles.EnterTheVerificationCode')}</FormHeaderTitle>
            </FormHeader>
            <FormHeaderDescription>{t('common:titles.VerificationTitle')}</FormHeaderDescription>
            <Row>
              <Col>
                <FormProvider {...formMethods}>
                  <Form>
                    <Row>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                          marginBottom: 24,
                        }}
                      >
                        <Col>
                          <FormTextField
                            name={'emailCode'}
                            placeholder={`* ${t('common:inputs.EnterEmailCode')}`}
                            placeholderTextColor={'#fff'}
                            rules={{
                              required: true,
                              pattern: /^[0-9]{6}$/,
                            }}
                            message={'Wrong Code'}
                          />
                        </Col>
                        <Col sm={2}>
                          <ResendButton type={'email'} code={code} />
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                        }}
                      >
                        <Col>
                          <FormTextField
                            name={'smsCode'}
                            placeholder={`* ${t('common:inputs.EnterSMSCode')}`}
                            placeholderTextColor={'#fff'}
                            rules={{
                              required: true,
                              pattern: /^[0-9]{6}$/,
                            }}
                            message={'Wrong Code'}
                          />
                        </Col>
                        <Col sm={2}>
                          <ResendButton type={'sms'} code={code} />
                        </Col>
                      </div>
                    </Row>
                  </Form>
                </FormProvider>
              </Col>
            </Row>
            <Row className='text-center'>
              <Col style={{ paddingTop: 48, paddingBottom: 48 }}>
                <Button
                  size='lg'
                  variant='info'
                  disabled={isLoading}
                  onClick={formMethods.handleSubmit(onSubmit)}
                  className='text-white'
                >
                  {t('common:buttons.SignIn')}
                </Button>
              </Col>
            </Row>
          </FormBackground>
        </Col>
      </Row>
    </Container>
  );
}
export default VerificationJoinScreen;
