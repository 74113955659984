const LocalStorageBasicService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    try {
      localStorage.setItem('access_token', tokenObj.access_token);
      localStorage.setItem('refresh_token', tokenObj.refresh_token);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  function _setUserid(id) {
    try {
      localStorage.setItem('userid', id);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  function _getUserid() {
    try {
      return localStorage.getItem('userid');
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  function _getAccessToken() {
    try {
      return localStorage.getItem('access_token');
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  function _getRefreshToken() {
    try {
      return localStorage.getItem('refresh_token');
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  function _clearToken() {
    try {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    } catch (e) {
      console.log(e);
    }
  }

  function _clearUser() {
    try {
      localStorage.removeItem('userid');
    } catch (e) {
      console.log(e);
    }
  }

  function _setUserKeyACL(acl, index) {
    let acl_cached = localStorage.getItem('userACL');
    if (!acl_cached) {
      acl_cached = {};
    } else {
      acl_cached = JSON.parse(acl_cached);
    }
    acl_cached[index] = acl;
    localStorage.setItem('userACL', JSON.stringify(acl_cached));
  }

  function _getUserKeyACL(index) {
    let acl_cached = localStorage.getItem('userACL');
    if (acl_cached) {
      acl_cached = JSON.parse(acl_cached);
      if (acl_cached[index]) {
        return acl_cached[index];
      }
    }
    return null;
  }

  function _clearUserKeyACL() {
    localStorage.removeItem('userACL');
  }

  return {
    getService: _getService,

    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,

    clearToken: _clearToken,
    clearUser: _clearUser,

    getUserid: _getUserid,
    setUserid: _setUserid,

    setUserKeyACL: _setUserKeyACL,
    getUserKeyACL: _getUserKeyACL,
    clearUserKeyACL: _clearUserKeyACL,
  };
})();
export default LocalStorageBasicService;
