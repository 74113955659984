import Navbar from 'react-bootstrap/Navbar';
import { Container, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Logo } from 'assets';
import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import styled from 'styled-components';
type Props = {
  avatar?: string;
  isUser?: boolean;
  logout?: () => void;
  username?: string;
};
const NavLink = styled(Nav.Link)`
  color: #00bdff !important;
  font-family: DIN-Condensed;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const NavigationBar: React.FC<Props> = props => {
  const { t } = useTranslation(['common']);
  return (
    <Navbar collapseOnSelect expand={true} bg='#000' variant='dark'>
      <Container>
        <Link to='/home'>
          <Navbar.Brand>
            <img src={`${Logo}`} alt='Logo' />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse className='justify-content-end' id='responsive-navbar-nav'>
          <Nav>
            <LinkContainer to='/' onClick={props.logout}>
              <NavLink> {t('common:menu.SIGNOUT')}</NavLink>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavigationBar;
