import { components } from 'react-select';

export const { Option } = components;

export const CustomSelectOption = (props: any) => (
  <Option {...props}>
    {props.data.icon && (
      <img
        src={props.data.icon}
        style={{
          height: 40,
          width: 40,
          borderRadius: 10,
          borderColor: 'transparent',
          objectFit: 'contain',
          marginRight: 5,
        }}
      />
    )}
    {props.data.label}
  </Option>
);

export const CustomSelectValue = (props: any) => (
  <div>
    {props.data.icon && (
      <img
        src={props.data.icon}
        style={{
          height: 30,
          width: 30,
          borderRadius: 10,
          borderColor: 'transparent',
          objectFit: 'contain',
          marginRight: 5,
        }}
      />
    )}
    {props.data.label}
  </div>
);
export function CustomInput({ openCalendar, value, handleValueChange }: any) {
  return (
    <>
      <input
        style={{
          paddingTop: 11,
          width: '100%',
          backgroundColor: 'transparent',
          display: 'flex',
          outline: 0,
          borderStyle: 'solid',
          borderBottomColor: 'white',
          color: 'white',
          borderWidth: '0 0 1px',
          maxWidth: 0,
        }}
        placeholder={'Date'}
        onFocus={openCalendar}
        value={value}
        onChange={handleValueChange}
      />
    </>
  );
}
