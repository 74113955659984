import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import NavigationBar from 'components/navbar';
import PublicNavigationBar from 'components/navbar/public';
import { useAuthorization } from 'services';

export const ProtectedRoutes: React.FC = () => {
  const { user, signOut, isAppLoading } = useAuthorization();
  if (isAppLoading) return <div> loading </div>;
  return (
    <>
      {!user ? (
        <Navigate to='/' />
      ) : (
        <>
          <NavigationBar
            avatar={user?.profile?.image}
            username={user?.firstName}
            logout={() => signOut()}
          />
          <div style={{ height: 50 }} />
          <Outlet />
        </>
      )}
    </>
  );
};
export const UnprotectedRoutes: React.FC = () => {
  const { user, isAppLoading } = useAuthorization();
  if (isAppLoading) return <div> loading </div>;
  return (
    <>
      {!user ? (
        <>
          <PublicNavigationBar />
          <div style={{ height: 50 }} />
          <Outlet />
        </>
      ) : (
        <Navigate to='/home' />
      )}
    </>
  );
};
