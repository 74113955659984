const APIs = {
  abilities: {
    urac: {
      put: {
        '/account': {
          I: 'myProfile',
          A: 'update',
          M: 'Update my profile',
        },
      },
    },
  },
  versions: {
    urac: '3',
    oauth: '1',
    onboarding: '1',
  },
};

export default APIs;
