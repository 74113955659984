import { useDropzone } from 'react-dropzone';
import { IoCameraOutline } from 'react-icons/io5';
import { Colors } from 'theme/Colors';

import {
  AddImageContainer,
  EditProfileButton,
  EditProfileButtonIcon,
  ProfilePhoto,
  ProfileWrapper,
} from './AboutYourPeopleStyled';

export const AddImages = ({ images, onFileChange, onRemoveImage, name, deepSetName }: any) => {
  const onDropAccepted = async (files: any) => {
    if (files.length) {
      const imagesLength = images?.length || 0;
      onFileChange(imagesLength, files, name, deepSetName);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDropAccepted,
    multiple: true,
  });
  const removeFile = (index: any) => {
    onRemoveImage(index, name, deepSetName);
  };
  return (
    <AddImageContainer>
      <ProfileWrapper>
        <EditProfileButton {...getRootProps()}>
          {images?.length > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IoCameraOutline size={30} color={'white'} />
              <span style={{ color: '#EAEAEA', fontSize: 20, marginLeft: 10 }}>
                Add captures({images?.length})
              </span>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IoCameraOutline size={30} color={'white'} />
              <span style={{ color: '#EAEAEA', fontSize: 20, marginLeft: 10 }}>Add captures</span>
            </div>
          )}
          <input multiple name={name} {...getInputProps()} />
        </EditProfileButton>
      </ProfileWrapper>
      <div
        style={{
          paddingTop: 30,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {images?.map((file: any, i: any) => (
          <div key={file.name + '_' + i} style={{ position: 'relative' }}>
            <EditProfileButtonIcon
              onClick={() => removeFile(i)}
              name='ios-add'
              size={20}
              color={Colors.placeholder}
            />
            <ProfilePhoto src={file.preview} />
          </div>
        ))}
      </div>
      <div style={{ height: 1, backgroundColor: 'white', width: 730 }} />
    </AddImageContainer>
  );
};
