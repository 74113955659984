import { Colors } from 'theme/Colors';
import styled, { css } from 'styled-components';

export const Title = styled.h1`
  font-size: 79px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: DIN-Condensed;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`;
export const SubTitle = styled.span`
  font-size: 177px;
  color: ${Colors.white};
  font-weight: bold;
  text-transform: uppercase;
  font-family: DIN-Condensed;
  line-height: 85%;
  ${css`
    @media (max-width: 991px) {
      font-size: 90px;
    }
    @media (max-width: 768px) {
      font-size: 80px;
    }
  `}
`;
export const Description = styled.span`
  font-size: 32px;
  font-weight: lighter;
  line-height: 140%;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
export const StepNumber = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-family: DIN-Condensed;
  font-size: 149px;
  ${css`
    @media (max-width: 991px) {
      font-size: 100px;
    }
  `};
`;
export const StepDescription = styled.span`
  font-size: 24px;
  line-height: 140%;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
