import styled from 'styled-components';
import { IoAddOutline } from 'react-icons/io5';

export const ProfilePhoto = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 100px;
  object-fit: cover;
`;
export const EditProfileButton = styled.div`
  display: flex;
  background-color: #41444b;
  cursor: pointer;
  //margin-right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  transform: translate(180%, -50%);
`;
export const EditProfileButtonIcon = styled(IoAddOutline)`
  margin: 0;
`;
