import Dropzone from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { IoTrashSharp } from 'react-icons/io5';
import { Pdf } from 'assets';

export const FileInput = ({ control, name, onChangeField, onDelete }: any) => {
  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value, onBlur } }) => {
          return (
            <Dropzone
              onDrop={e => {
                onChange(e);
                onChangeField(e);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    paddingTop: 33,
                    paddingBottom: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    {...getRootProps()}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <img src={Pdf} style={{ height: 31, width: 25 }} />
                    <input {...getInputProps()} name={name} onBlur={onBlur} />
                    {value.length === 0 && (
                      <p style={{ fontWeight: 'bold', margin: 10 }}>UPLOAD PDF</p>
                    )}
                  </div>
                  {/* {value?.map((file: any, i: number) => ( */}
                  {value.length !== 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontWeight: 'bold', margin: 10 }}>{value[0]?.name}</p>
                      <div
                        onClick={() => {
                          onChange([]);
                          onDelete();
                        }}
                      >
                        <IoTrashSharp size={20} color={'white'} />
                      </div>
                    </div>
                  )}
                  {/* ))} */}
                </div>
              )}
            </Dropzone>
          );
        }}
        name={name}
        defaultValue={[]}
      />
      <div style={{ height: 1, backgroundColor: 'white' }} />
    </>
  );
};
