import styled, { css } from 'styled-components';

import { Colors } from 'theme/Colors';
import PhoneInput from 'react-phone-input-2';

export const InputText = styled.input<{ isRequired?: boolean; placeholderTextColor?: string }>`
  padding-bottom: 7px;
  outline: 0;
  min-height: 20px;
  background-color: transparent;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  border-style: solid;
  border-bottom-color: ${props => (props.isRequired ? '#FFF38A' : '#FFF')};
  width: 100%;
  border-width: 0 0 1px;
  color: ${Colors.white};
  ${props =>
    props.placeholderTextColor &&
    css`
      ::placeholder {
        color: ${Colors.white};
      }
    `}
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
`;
export const InputContainer = styled.div`
  width: 210px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const PhoneInputs = styled(PhoneInput)`
  padding-top: 10px;
  outline: 0;
  min-height: 20px;
  background-color: transparent;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  border-style: solid;
  border-bottom-color: #fff;
  width: 300px;
  border-width: 0 0 1px;
  color: ${Colors.white};
`;
