import React, { createContext, useState, useContext } from 'react';

const FormDataContext = createContext<any>({});

export const FormDataProvider: React.FC = ({ children }) => {
  const [formData, setFormData] = useState<any>({});
  const setValues = (values: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      ...values,
    }));
  };

  return (
    <FormDataContext.Provider value={{ formData, setValues }}>{children}</FormDataContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);
