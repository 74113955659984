import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import styled, { css, keyframes } from 'styled-components';
import { Colors } from 'theme/Colors';

export const MainTitle = styled.h1`
  font-size: 77px;
  line-height: 85%;
  font-weight: bold;
  font-family: DIN-Condensed;
  ${css`
    @media (max-width: 768px) {
      font-size: 50px;
    }
  `}
`;
export const EmpireDescription = styled.span`
  font-size: 24px;
  line-height: 140%;
  ${css`
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `}
`;
export const SponsorsDescription = styled.span`
  font-size: 24px;
  line-height: 140%;
`;
export const FormWrapper = styled.form`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-top: 31px;
  padding-bottom: 31px;
`;
export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: ${Colors.white};
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Indicator = styled.div`
  width: 27px;
  height: 27px;
  background-color: ${Colors.white};
  // position: absolute;
  top: 0em;
  left: -1.6em;
  border: 1px solid ${Colors.white};
  border-radius: 15px;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  ${Input}:not(:disabled):checked & {
    background: transparent;
  }

  &::after {
    content: '';
    position: relative;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0.1em;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: solid #263238;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    padding-left: 0.5em;
  }
`;

export const Checkbox = ({ name, id, label, disabled, onFieldChange }: any) => {
  const { control } = useFormContext();
  return (
    <Label htmlFor={id} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur } }) => {
          return (
            <Input
              id={id}
              type='checkbox'
              disabled={disabled}
              checked={value || false}
              onChange={(e: any) => {
                onChange(e);
                onFieldChange(e);
              }}
              onBlur={onBlur}
              name={name}
            />
          );
        }}
      />
      <Indicator />
      <span style={{ paddingLeft: 10 }}>{label}</span>
    </Label>
  );
};
