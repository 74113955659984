import { FormTextField } from 'components';
import { DeleteButton, Divider } from 'components/shared';
import { getKeys } from 'helpers/getKeys';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { deepSetArray } from 'utils/deepSetLib';
import { SponsorsTypeProp } from 'views/urac/types';
import { MainTitle, EmpireDescription, Checkbox, FormContainer } from './SponsorsStyled';

function SponsorsScreen({ inputs, setFields, errors, setErrors }: any) {
  const [isPhoneDelete, setIsPhoneDelete] = useState(false);

  const formMethods = useForm<SponsorsTypeProp>({
    mode: 'onChange',
    defaultValues: {
      sponsors: inputs.sponsors.map((item: any) => ({
        name: item.name,
        type: item.type,
        description: item.description,
        clause: item.clause,
      })),
    },
  });
  const { fields, append, remove } = useFieldArray<SponsorsTypeProp>({
    name: 'sponsors',
    control: formMethods.control,
  });
  const sponsorsField = useWatch<SponsorsTypeProp>({
    control: formMethods.control,
    name: 'sponsors',
  });
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsPhoneDelete(false);
      } else if (window.innerWidth < 768) {
        setIsPhoneDelete(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const onDeleteFields = useCallback(
    (index: any) => {
      if (index === 0 && inputs?.sponsors?.length === 1) {
        setFields((prevState: any) => {
          prevState?.sponsors?.splice(index, 1, {});
          return prevState;
        });
      } else {
        setFields((prevState: any) => {
          prevState?.sponsors?.splice(index, 1);
          return prevState;
        });
      }
      setErrors((prevState: any) => {
        const updatedErrors = {
          ...prevState,
          sponsors: [...prevState.sponsors.slice(0, index), ...prevState.sponsors.slice(index + 1)],
        };
        return updatedErrors;
      });
      remove(index);
    },
    [setFields]
  );
  const onFieldChange = (event: any, i: number) => {
    const { value, name } = event.target;
    setFields((prevState: any) => {
      return deepSetArray(prevState, name, value);
    });
    if (inputs?.sponsors?.[i].type) {
      if (value) {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, '');
        });
      } else if (!value) {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, 'Required');
        });
      }
    } else {
      // !type
      if (value) {
        setErrors((prevState: any) => {
          return deepSetArray(prevState, name, '');
        });
      } else if (!value) {
        if (name === `sponsors.${i}.name`) {
          setErrors((prevState: any) => {
            return deepSetArray(prevState, name, '');
          });
          setErrors((prevState: any) => {
            return deepSetArray(prevState, `sponsors.${i}.description`, '');
          });
          setErrors((prevState: any) => {
            return deepSetArray(prevState, `sponsors.${i}.clause`, '');
          });
        } else if (!inputs?.sponsors?.[i]?.name && name === `sponsors.${i}.description`) {
          setErrors((prevState: any) => {
            return deepSetArray(prevState, name, '');
          });
        } else if (!!inputs?.sponsors?.[i]?.name && name === `sponsors.${i}.description`) {
          setErrors((prevState: any) => {
            return deepSetArray(prevState, name, 'Required');
          });
        } else {
          setErrors((prevState: any) => {
            return deepSetArray(prevState, name, 'Required');
          });
        }
      }
    }
  };

  const onCheckChanges = (event: any, i: number) => {
    const { checked, name } = event.target;
    setFields((prevState: any) => {
      return deepSetArray(prevState, name, checked);
    });
    if (!checked) {
      setFields((prevState: any) => {
        return deepSetArray(prevState, `sponsors.${i}.clause`, '');
      });
      setErrors((prevState: any) => {
        return deepSetArray(prevState, `sponsors.${i}.clause`, '');
      });
      setErrors((prevState: any) => {
        return deepSetArray(prevState, `sponsors.${i}.name`, '');
      });
      setErrors((prevState: any) => {
        return deepSetArray(prevState, `sponsors.${i}.description`, '');
      });
    }
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields]);

  useEffect(() => {
    if (
      getKeys(sponsorsField![sponsorsField!.length - 1]).some(
        key => !!sponsorsField![sponsorsField!.length - 1][key]
      )
    ) {
      append({});
    }
  }, [sponsorsField]);

  return (
    <div>
      <Row className='text-center'>
        <Col>
          <MainTitle>{t('common:titles.SponsorsTitle')}</MainTitle>
        </Col>
      </Row>
      <Row className='text-center'>
        <Col>
          <EmpireDescription>{t('common:titles.SponsorsDescription')}</EmpireDescription>
        </Col>
      </Row>
      <FormProvider {...formMethods}>
        <FormContainer>
          {fields.map((item: any, i: any) => {
            const checkFieldBox = formMethods.watch(`sponsors.${i}.type`, false);
            return (
              <div key={item.id} style={{ width: '100%' }}>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end',
                    marginBottom: 25,
                  }}
                  xs='auto'
                  md='auto'
                  lg='auto'
                  xl='auto'
                >
                  <div style={{ width: !isPhoneDelete ? 'fit-content' : '100%' }}>
                    <Col style={{ width: !isPhoneDelete ? 'fit-content' : '100%' }}>
                      <div style={{ position: 'relative' }}>
                        <FormTextField
                          onFieldChange={(event: any) => {
                            onFieldChange(event, i);
                          }}
                          name={`sponsors.${i}.name`}
                          placeholder={`${t('common:inputs.SponsorCompanyName')}`}
                          rules={{
                            required: true,
                          }}
                          style={{
                            borderColor:
                              errors?.sponsors?.[i] &&
                              errors?.sponsors?.[i].name === 'Required' &&
                              '#FFF38A',
                          }}
                        />
                        {errors?.sponsors?.[i] && errors?.sponsors?.[i].name === 'Required' && (
                          <span
                            style={{
                              position: 'absolute',
                              bottom: -20,
                              left: 0,
                              color: '#FFF38A',
                              fontSize: 12,
                            }}
                          >
                            {errors?.sponsors?.[i].name}
                          </span>
                        )}
                      </div>
                    </Col>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 30,
                      justifyContent: !isPhoneDelete ? 'flex-start' : 'space-between',
                      alignItems: 'center',
                      width: !isPhoneDelete ? 'fit-content' : '100%',
                    }}
                  >
                    <Col>
                      <Checkbox
                        onFieldChange={(event: any) => {
                          onCheckChanges(event, i);
                        }}
                        name={`sponsors.${i}.type`}
                        label='Exclusive'
                      />
                    </Col>
                    {i >= 0 && (!checkFieldBox || !isPhoneDelete) && (
                      <div style={{ paddingLeft: 20 }}>
                        <DeleteButton
                          onClick={() => (fields.length - 1 ? onDeleteFields(i) : null)}
                        />
                      </div>
                    )}
                  </div>
                </Row>
                <Row
                  style={{
                    paddingBlock: 5,
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isPhoneDelete ? 'column' : 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      gap: 20,
                      width: '100%',
                      padding: 0,
                    }}
                  >
                    {checkFieldBox && (
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flex: 0,
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            width: isPhoneDelete ? '100%' : 'fit-content',
                          }}
                        >
                          <FormTextField
                            isTextArea
                            onFieldChange={(event: any) => {
                              onFieldChange(event, i);
                            }}
                            name={`sponsors.${i}.description`}
                            placeholder={'Describe the details of this relationship...'}
                            rules={{
                              required: false,
                            }}
                            style={{
                              width: isPhoneDelete ? '100%' : 380,
                              borderColor:
                                errors?.sponsors?.[i] &&
                                errors?.sponsors?.[i].description === 'Required' &&
                                '#FFF38A',
                            }}
                          />
                          {errors?.sponsors?.[i] &&
                            errors?.sponsors?.[i].description === 'Required' && (
                              <span
                                style={{
                                  position: 'absolute',
                                  bottom: -14,
                                  left: 0,
                                  color: '#FFF38A',
                                  fontSize: 12,
                                }}
                              >
                                {errors?.sponsors?.[i].description}
                              </span>
                            )}
                        </div>
                      </Col>
                    )}
                    {checkFieldBox && (
                      <>
                        <Col
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flex: 0,
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                              width: isPhoneDelete ? '100%' : 'fit-content',
                            }}
                          >
                            <FormTextField
                              isTextArea
                              onFieldChange={(event: any) => {
                                onFieldChange(event, i);
                              }}
                              name={`sponsors.${i}.clause`}
                              placeholder={'Paste your exclusivity clause here...'}
                              rules={{
                                required: false,
                              }}
                              style={{
                                width: isPhoneDelete ? '100%' : 380,
                                borderColor:
                                  errors?.sponsors?.[i] &&
                                  errors?.sponsors?.[i].clause === 'Required' &&
                                  '#FFF38A',
                              }}
                            />
                            {errors?.sponsors?.[i] && errors?.sponsors?.[i].clause === 'Required' && (
                              <span
                                style={{
                                  position: 'absolute',
                                  bottom: -14,
                                  left: 0,
                                  color: '#FFF38A',
                                  fontSize: 12,
                                }}
                              >
                                {errors?.sponsors?.[i].clause}
                              </span>
                            )}
                          </div>
                        </Col>
                      </>
                    )}
                  </div>

                  {isPhoneDelete && checkFieldBox && i >= 0 && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
                      <DeleteButton
                        onClick={() => (fields.length - 1 ? onDeleteFields(i) : null)}
                      />
                    </div>
                  )}
                  {fields.length > 1 ? <Divider style={{ width: '100%' }} /> : null}
                </Row>
              </div>
            );
          })}
        </FormContainer>
      </FormProvider>
    </div>
  );
}

export default SponsorsScreen;
