import { Colors } from 'theme/Colors';
import styled from 'styled-components';

export const LetsSignTitle = styled.span`
  font-size: 77px;
  line-height: 85%;
  color: ${Colors.white};
  font-weight: bold;
  max-width: none;
  font-family: DIN-Condensed;
`;
export const LetsSignDescription = styled.span`
  font-size: 24px;
  color: ${Colors.white};
  max-width: none;
`;
