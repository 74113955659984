import { AbilityBuilder } from '@casl/ability';
import { UracService } from './index';
import APIs from 'availableAbilities';
import { toast } from 'react-toastify';

const uracService = UracService.getService();

async function updateAbility(ability, extKey) {
  const { can, rules } = new AbilityBuilder(ability);
  let userACL = null;
  try {
    userACL = await uracService.getUserACL(extKey);
  } catch (e) {
    toast.error(e.message);
  }
  if (userACL && userACL.finalACL) {
    let abilityAdded = 0;
    let abilityLogMsg = [];
    if (APIs && APIs.abilities) {
      //let categories = Object.keys(APIs.abilities);
      //for (let x = 0; x < categories.length; x++) {
      let abilities = APIs.abilities;
      let services = Object.keys(abilities);
      for (let i = 0; i < services.length; i++) {
        if (userACL.finalACL[services[i]]) {
          let allAPiAvailable = false;
          if (
            userACL.acl[services[i]] &&
            userACL.acl[services[i]][APIs.versions[services[i]]] &&
            (!userACL.acl[services[i]][APIs.versions[services[i]]].apisPermission ||
              userACL.acl[services[i]][APIs.versions[services[i]]].apisPermission !== 'restricted')
          ) {
            allAPiAvailable = true;
          }
          let methods = Object.keys(abilities[services[i]]);
          for (let j = 0; j < methods.length; j++) {
            let apis = Object.keys(abilities[services[i]][methods[j]]);
            for (let k = 0; k < apis.length; k++) {
              let enableThisAbility = false;
              if (!allAPiAvailable) {
                if (
                  userACL.finalACL[services[i]][methods[j]] &&
                  userACL.finalACL[services[i]][methods[j]].includes(apis[k])
                ) {
                  enableThisAbility = true;
                }
              } else {
                enableThisAbility = true;
              }
              if (enableThisAbility) {
                if (Array.isArray(abilities[services[i]][methods[j]][apis[k]])) {
                  for (let l = 0; l < abilities[services[i]][methods[j]][apis[k]].length; l++) {
                    if (
                      abilities[services[i]][methods[j]][apis[k]][l].I &&
                      abilities[services[i]][methods[j]][apis[k]][l].A
                    ) {
                      can(
                        abilities[services[i]][methods[j]][apis[k]][l].I,
                        abilities[services[i]][methods[j]][apis[k]][l].A
                      );
                      abilityAdded++;
                    } else {
                      abilityLogMsg.push(
                        'Skipping: ' + abilities[services[i]][methods[j]][apis[k]][l].M
                      );
                    }
                  }
                } else {
                  if (
                    abilities[services[i]][methods[j]][apis[k]].I &&
                    abilities[services[i]][methods[j]][apis[k]].A
                  ) {
                    can(
                      abilities[services[i]][methods[j]][apis[k]].I,
                      abilities[services[i]][methods[j]][apis[k]].A
                    );
                    abilityAdded++;
                  } else {
                    abilityLogMsg.push(
                      'Skipping: ' + abilities[services[i]][methods[j]][apis[k]].M
                    );
                  }
                }
              } else {
                if (Array.isArray(abilities[services[i]][methods[j]][apis[k]])) {
                  for (let l = 0; l < abilities[services[i]][methods[j]][apis[k]].length; l++) {
                    abilityLogMsg.push(
                      'Disabled: ' + abilities[services[i]][methods[j]][apis[k]][l].M
                    );
                  }
                } else {
                  abilityLogMsg.push('Disabled: ' + abilities[services[i]][methods[j]][apis[k]].M);
                }
              }
            }
          }
        }
      }
      //}
    }
    console.log(abilityLogMsg);
    console.log('Abilities Added: ' + abilityAdded);
  }
  ability.update(rules);
}

const AbilitiesService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  async function _createUserAbilities(ability) {
    return new Promise(resolve => {
      ability.on('updated', resolve);
      updateAbility(ability, null);
    });
  }

  async function _updateUserAbilities(ability, extKey) {
    return new Promise(resolve => {
      ability.on('updated', resolve);
      updateAbility(ability, extKey);
    });
  }

  return {
    getService: _getService,
    createUserAbilities: _createUserAbilities,
    updateUserAbilities: _updateUserAbilities,
  };
})();
export default AbilitiesService;
