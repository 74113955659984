import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'theme/Colors';
import { Spinner } from 'react-bootstrap';

export type ButtonContainerTypeProp = {
  spaceAround?: string;
  marginTop?: number;
  starting?: string;
  width?: number;
  ml?: number;
  mr?: number;
};
export type ButtonWrapperTypeProp = {
  width?: number;
  color?: string;
  borderRadius?: number;
  background?: string;
  borderColor?: string;
};
type ButtonHolderProps = {
  onClick: () => void;
  loading?: boolean;
  background?: string;
  borderColor?: string;
};
export type ButtonTextTypeProp = {
  color?: string;
  fontSize?: number;
  fontWeight?: string;
  fontFamily?: string;
};
export type ButtonHolderTypeProp = {
  onPress: () => void;
  loading?: boolean | undefined;
  title: string;
  disabled?: boolean;
  background?: string;
  fontSize?: number;
  width?: number;
  borderColor?: string;
  ml?: number;
  mr?: number;
};
const ButtonWrapper = styled.div<ButtonWrapperTypeProp>`
  display: flex;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: 45px;
  background-color: ${props => props.background || Colors.primary};
  justify-content: center;
  align-items: center;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 5)}px;
  border-color: ${props => props.borderColor || 'transparent'};
  border-width: 2px;
  border-style: solid;
  align-self: center;
  :hover {
    cursor: pointer;
  }
`;

export const ButtonText = styled.span<ButtonTextTypeProp>`
  color: ${({ color }) => color || Colors.white};
  font-size: ${({ fontSize }) => fontSize || 16}px;
  font-weight: ${({ fontWeight }) => fontWeight || 'semi-bold'};
`;

export const ButtonsContainer = styled.div<ButtonContainerTypeProp>`
  display: flex;
  justify-content: ${props => (props.spaceAround ? 'space-around' : 'space-between')};
  margin-bottom: 20px;
  margin-top: ${props => (props.marginTop ? props.marginTop : 10)}px;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  align-items: ${props => (!props.starting ? 'center' : 'flex-start')};
  align-self: ${props => (!props.starting ? 'center' : 'flex-start')};
  padding-left: ${props => (props.ml ? 0 : 20)}px;
  padding-right: ${props => (props.mr ? 0 : 20)}px;
  border: 0;
  background-color: transparent;
  ${css`
    :hover {
      background-color: transparent;
    }
  `}
`;

export const ButtonHolder: React.FC<ButtonHolderProps> = props => (
  <ButtonWrapper
    borderColor={props.borderColor}
    background={props.background}
    onClick={props.onClick}
  >
    {props.loading ? <Spinner animation='grow' variant='light' /> : props.children}
  </ButtonWrapper>
);
/**
 * @example
 * <Button onPress={()=>console.log('')} title={'anyTitle'} />
 */
export const Buttons: React.FC<ButtonHolderTypeProp> = ({
  onPress,
  loading,
  title,
  background,
  fontSize,
  width,
  borderColor,
  ml,
  mr,
}) => (
  <ButtonsContainer ml={ml} mr={mr} width={width}>
    <ButtonHolder
      borderColor={borderColor}
      background={background}
      onClick={onPress}
      loading={loading}
    >
      <ButtonText fontSize={fontSize}>{title}</ButtonText>
    </ButtonHolder>
  </ButtonsContainer>
);
