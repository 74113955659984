import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Lock } from 'assets';
import { FormTextField } from 'components';
import { ErrorText } from 'components/shared';
import { UracService } from 'services/apis';
import { isEmail } from 'utils/validators';

import { joinByMobileTypeProp } from '../types';
import {
  FormBackground,
  FormHeader,
  FormHeaderDescription,
  FormHeaderTitle,
  MainTitle,
  PhoneContainer,
  SubMainTitle,
} from './JoinStyled';
import { useFormData } from 'services/context/FormDataProvider';

const onBoarding = UracService.getService();

export const JoinByMobileScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isPhoneDelete, setIsPhoneDelete] = useState(false);

  const { formData, setValues } = useFormData();
  const formMethods = useForm<joinByMobileTypeProp>({
    mode: 'onChange',
    defaultValues: {
      phone: formData.phone,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      code: formData.code,
      phone_key: '1',
    },
  });
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsPhoneDelete(false);
      } else if (window.innerWidth < 768) {
        setIsPhoneDelete(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSubmit = async (data: joinByMobileTypeProp) => {
    const concatNumber = `${data.phone_key}${data.phone}`;
    const joinFields = {
      code: data.code,
      email: data.email,
      phone: concatNumber,
      firstName: data.firstName,
      lastName: data.lastName,
    } as joinByMobileTypeProp;
    setValues(data);
    try {
      setIsLoading(true);
      await onBoarding.validateInvite(joinFields).then((userStatus: any) => {
        setIsLoading(false);
        // add the user status
        userStatus.status === 'active'
          ? navigate('/')
          : navigate('/validate/join', { state: joinFields });
      });
    } catch (error: any) {
      setIsLoading(false);
      formMethods.setError('code', { message: error.message });
    }
  };

  return (
    <Container className='text-white'>
      <Row>
        <Col>
          {!isPhoneDelete ? (
            <MainTitle>
              if you
              <br /> know <br />
              you know
            </MainTitle>
          ) : (
            <MainTitle>if you know you know</MainTitle>
          )}

          <SubMainTitle>Include your invite code.</SubMainTitle>
        </Col>
        <Col lg={5} style={{ paddingLeft: '0', paddingRight: '0' }}>
          <FormBackground>
            <FormHeader>
              <img src={Lock} style={{ height: 33, width: 25, marginRight: 10 }} />
              <FormHeaderTitle>{t('common:titles.GetStartedText')}</FormHeaderTitle>
            </FormHeader>
            <FormHeaderDescription>
              {t('common:titles.GetStartedDescription')}
              <Link to={'/'} style={{ fontSize: 14, color: '#fff', fontWeight: 'bold' }}>
                {t('common:menu.Signin')}
              </Link>
            </FormHeaderDescription>
            <Row>
              <Col>
                <FormProvider {...formMethods}>
                  <Form style={{ display: 'block' }}>
                    <FormTextField
                      name={'firstName'}
                      placeholder={`* ${t('common:inputs.FirstName')}`}
                      rules={{
                        required: true,
                      }}
                      message={'Required'}
                      style={{ marginTop: 20, fontSize: 14 }}
                      placeholderTextColor={'#fff'}
                    />
                    <FormTextField
                      name={'lastName'}
                      placeholder={`* ${t('common:inputs.LastName')}`}
                      rules={{
                        required: true,
                      }}
                      message={'Required'}
                      style={{ marginTop: 20, fontSize: 14 }}
                      placeholderTextColor={'#fff'}
                    />
                    <FormTextField
                      name={'code'}
                      placeholder={`* ${t('common:inputs.InviteCode')}`}
                      rules={{
                        required: true,
                        pattern: /^[0-9]{6}$/,
                      }}
                      message={'Wrong Code'}
                      style={{ marginTop: 20, fontSize: 14 }}
                      placeholderTextColor={'#fff'}
                    />
                    <PhoneContainer>
                      <FormTextField
                        name={'phone_key'}
                        isPhone
                        rules={{
                          required: true,
                        }}
                        message={'required'}
                        style={{ marginTop: 28 }}
                        placeholderTextColor={'#fff'}
                      />
                      <FormTextField
                        name={'phone'}
                        isNumber
                        placeholder={`* ${t('common:inputs.PhoneNumber')}`}
                        rules={{
                          required: true,
                        }}
                        message={'Required'}
                        style={{ marginTop: 20, fontSize: 14 }}
                        placeholderTextColor={'#fff'}
                      />
                    </PhoneContainer>
                    <FormTextField
                      name={'email'}
                      placeholder={`* ${t('common:inputs.Email')}`}
                      placeholderTextColor={'#fff'}
                      rules={{
                        required: true,
                        pattern: isEmail,
                      }}
                      message={'Required'}
                      style={{ marginTop: 20, fontSize: 14 }}
                    />
                    <p>
                      Creating an account means you&apos;re ok with our{' '}
                      <a href='#'>Terms of Service </a>
                      and <a href='#'>Privacy Policy</a>.
                    </p>
                    {formMethods.formState.errors != undefined && (
                      <ErrorText color={'#FFF38A'}>
                        {formMethods.formState.errors.code?.message}
                      </ErrorText>
                    )}
                  </Form>
                </FormProvider>
              </Col>
            </Row>
            <Row className='text-center'>
              <Col>
                <Button
                  size='lg'
                  variant='info'
                  disabled={isLoading}
                  onClick={formMethods.handleSubmit(onSubmit)}
                  className='text-white'
                >
                  {t('common:buttons.LetsGo')}
                </Button>
              </Col>
            </Row>
          </FormBackground>
        </Col>
      </Row>
    </Container>
  );
};
