import { Col, Container, Row } from 'react-bootstrap';
import { Heading, NormalText } from '../handleConflict/handleConflict.style';
import { t } from 'i18next';
import { Button } from 'views/home/HomeStyled';
import { useState } from 'react';
import { Textarea } from './GetInTouch.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { notificationServie } from 'services/apis/NotificationService';
import { toast } from 'react-toastify';

export const GetInTouch = () => {
  const navigate = useNavigate();
  const location = useLocation() as any;

  const [message, setMessage] = useState<string>('');

  const onSend = () => {
    notificationServie
      .askForHelp({
        message: message,
        handles: location.state.handles.map((handle: any) => ({
          handle: handle.handle,
          socialMedia: handle.socialMedia,
        })),
        onboardingId: location.state.itemId,
      })
      .then(() => {
        toast.success('Message sent successfully');
        navigate(-1);
      });
  };
  return (
    <Container>
      <div>
        <Heading className='text-center'>{t('common:getInTouchScreen.anythingWrong')}</Heading>
      </div>
      <Row className='justify-content-center mb-3'>
        <Col sm={12} lg={9} xl={10}>
          <NormalText fontSize={24} className='text-center'>
            {t('common:getInTouchScreen.description')}
          </NormalText>
        </Col>
      </Row>
      <div className='py-3 text-center'>
        {/* <Row className='justify-content-center'>
          <Col sm={10} md={8} lg={6}>
            <Input
              placeholder={t('common:getInTouchScreen.subject')}
              className='mb-5 w-100'
            />
          </Col>
        </Row> */}
        <Row className='justify-content-center'>
          <Col sm={10} md={8} lg={6}>
            <Textarea
              placeholder={t('common:getInTouchScreen.descriptionPlaceholder')}
              onChange={e => setMessage(e.target.value)}
              value={message}
              rows={5}
              className='mb-5 w-100'
            />
          </Col>
        </Row>
        <div className='flex justify-content-center align-items-center'>
          <Button
            onClick={() => navigate(-1)}
            bgColor='rgba(255, 255, 255, 0.1)'
            className='me-sm-3 mb-4 mb-sm-0'
          >
            {t('common:getInTouchScreen.goBack')}
          </Button>
          <Button onClick={onSend}>{t('common:getInTouchScreen.send')}</Button>
        </div>
      </div>
    </Container>
  );
};
