import React from 'react';
import { OnboardingService } from 'services/apis';
import { ResendContainer, ResendTitle } from './ResendStyled';
const onBoarding = OnboardingService.getService();

export const ResendButton: React.FC<any> = (type, code) => {
  const sendCode = async () => {
    try {
      await onBoarding.resendCode(type, code);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ResendContainer>
      <div
        style={{ background: 'transparent', border: 'none', color: 'white' }}
        onClick={() => sendCode()}
      >
        <ResendTitle>resend</ResendTitle>
      </div>
    </ResendContainer>
  );
};
