import styled from 'styled-components';

export const FooterContainer = styled.div`
  overflow: auto;
  height: inherit;

  margin-top: 1rem;
  padding-bottom: 1rem;
  background-color: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  z-index: 0;
  flex: 1;
`;
