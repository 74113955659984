import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n.js';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AbilityProvider } from 'services/context/AbilityProvider';
import { AuthProvider } from 'services/context/AuthProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { GlobalStyle } from 'components/shared';
import { FormDataProvider } from 'services/context/FormDataProvider';

ReactDOM.render(
  <React.StrictMode>
    <FormDataProvider>
      <AbilityProvider>
        <AuthProvider>
          <BrowserRouter>
            <GlobalStyle />
            <App />
          </BrowserRouter>
        </AuthProvider>
      </AbilityProvider>
    </FormDataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
