import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { OnboardingService } from 'services/apis';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LetsSignDescription, LetsSignTitle } from './SignStyled';
import { t } from 'i18next';

const onBoarding = OnboardingService.getService();

const checkStatus = async (
  setContractStatus: any,
  navigate: any,
  setIsLoading: any,
  membership: any,
  itemId: any
) => {
  try {
    const status = await onBoarding.checkStatusById(itemId);
    if (status === 'waitingForSignature' || status === 'notSigned') {
      setContractStatus(status);
      setIsLoading(false);
    } else if (status === 'signed') {
      navigate('/welcome', {
        state: { membership, itemId },
      });
    } else {
      navigate('/home', { replace: true });
    }
  } catch (error: any) {
    toast.error(error.message);
    setIsLoading(false);
  }
};

export const ResignContract: React.FC = () => {
  const [contractStatus, setContractStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const routes = useLocation() as any;
  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await onBoarding.updateContractResend().then((res: any) => {
        if (res.data.url) {
          window.open(res.data.url, '_self');
        }
        if (res.data.message) {
          toast.info(res.data.message);
          setIsLoading(false);
        }
      });
    } catch (error: any) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };
  const onRefresh = async () => {
    setIsLoading(true);
    try {
      await checkStatus(
        setContractStatus,
        navigate,
        setIsLoading,
        routes.state?.membership,
        routes.state?.itemId
      );
    } catch (error: any) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async function () {
      setIsLoading(true);
      try {
        await checkStatus(
          setContractStatus,
          navigate,
          setIsLoading,
          routes.state?.membership,
          routes.state?.itemId
        );
      } catch (error: any) {
        toast.error(error.message);
        setIsLoading(false);
      }
    })();
  }, []);
  return (
    <Container className='text-center text-white'>
      {contractStatus === 'waitingForSignature' && (
        <>
          <Row>
            <Col>
              <LetsSignTitle style={{ textAlign: 'left' }}>
                {t('common:titles.AlmostThere').toUpperCase()}
              </LetsSignTitle>
              <LetsSignDescription style={{ textAlign: 'left' }}>
                {t('common:titles.AlmostThereDescription')}
                <span style={{ color: '#01BDFF', cursor: 'pointer', fontWeight: 'bold' }}>
                  {' '}
                  {t('common:buttons.SendUs')}
                </span>
              </LetsSignDescription>
            </Col>
          </Row>
          <Row style={{ display: 'flex', alignSelf: 'flex-end' }}>
            <Col style={{ display: 'flex' }}>
              <Button
                size='lg'
                variant='info'
                className='text-white'
                disabled={isLoading}
                onClick={onRefresh}
              >
                {t('common:buttons.Refresh')}
              </Button>
              <Button
                size='lg'
                style={{ marginLeft: 20 }}
                variant='outline-info'
                className='text-white'
                disabled={isLoading}
                onClick={onSubmit}
              >
                {t('common:buttons.SignAgain')}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {contractStatus === 'notSigned' && (
        <>
          <Row>
            <Col>
              <LetsSignTitle style={{ textAlign: 'left' }}>
                {t('common:titles.NotSigned').toUpperCase()}
              </LetsSignTitle>
              <LetsSignDescription>
                {t('common:titles.NotSignDescription')}
                <span
                  style={{ color: '#01BDFF', cursor: 'pointer', fontWeight: 'bold' }}
                  onClick={onSubmit}
                >
                  {t('common:buttons.ClickHere')}
                </span>{' '}
                {t('common:titles.NeedHelp')}
                <span style={{ color: '#01BDFF', cursor: 'pointer' }}>
                  {' '}
                  {t('common:buttons.SendUs')}
                </span>
              </LetsSignDescription>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
