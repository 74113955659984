import { Colors } from 'theme/Colors';
import styled from 'styled-components';

export const Heading = styled.h1`
  color: ${Colors.white};
  font-family: DIN-Condensed;
  font-weight: bold;
  font-size: 80px;
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-family: 'Open Sans';
  font-size: 16;
  color: ${Colors.white};
`;
type NText = {
  italic?: boolean;
  fontSize: number;
};
export const NormalText = styled.div<NText>`
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: ${props => (props.italic ? 'italic' : 'unset')};
  font-size: ${props => `${props.fontSize}px`};
  color: ${Colors.white};
`;
type IHeading = {
  fontSize?: number;
  color?: string;
};
export const Heading2 = styled.h1<IHeading>`
  color: ${props => props.color || Colors.white};
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: ${props => `${props.fontSize || 24}px`};
  margin: 0;
`;
