import { FileInput } from 'components/form/FileInput';
import { t } from 'i18next';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { deepSetArray, deepSet } from 'utils/deepSetLib';
import { MainTitle, EmpireDescription } from './AboutYourPeopleStyled';
import { AddImages } from './AddImages';

function AboutYourPeople({ inputs, setFields }: any) {
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: {
      presentation: inputs.presentation ? [inputs.presentation] : [],
      images: inputs.socialMediaHandles.map((socialMedia: any) => ({
        images: socialMedia.images,
      })),
    },
  });

  const removeImage = (socialMediaIndex: number, index: number) => {
    setFields((prevState: any) => {
      const newState = { ...prevState };
      newState.socialMediaHandles[socialMediaIndex].images = newState?.socialMediaHandles[
        socialMediaIndex
      ].images?.filter((_: any, i: number) => i !== index);
      return newState;
    });
  };

  const selectImages = async (index: number, e: any, deepSetName: string) => {
    const filePreview = e.map((file: Blob | MediaSource) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFields((prevState: any) => {
      const newState = { ...prevState };
      filePreview.map((file: any, _index: number) => {
        const _name = deepSetName + '.' + (index + _index);
        deepSetArray(newState, _name, file);
      });
      return newState;
    });
  };
  const deletePresentation = () => {
    setFields((prevState: any) => {
      const newState = { ...prevState };
      deepSet(newState, 'presentation', null);
      return newState;
    });
  };
  const selectPresentation = (e: any) => {
    const selectPdf = Object.assign(e[0], {
      preview: URL.createObjectURL(e[0]),
    });
    setFields((prevState: any) => {
      const newState = { ...prevState };
      deepSet(newState, 'presentation', selectPdf);
      return newState;
    });
  };
  return (
    <Container style={{ textAlign: 'center' }}>
      <Row>
        <Col>
          <MainTitle>{t('common:titles.AboutYourPeopleTitle')}</MainTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <EmpireDescription>
            {t('common:titles.AboutYourPeopleDescription')}
            <br />
            {t('common:titles.AboutYourPeopleSubDescription')}
          </EmpireDescription>
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <FormProvider {...formMethods}>
          <Form>
            <Col style={{ paddingTop: 32, paddingBottom: 48 }}>
              <h4 style={{ color: '#EAEAEA', fontSize: 32 }}>
                {t('common:titles.PresentationTitle')}
              </h4>
              <FileInput
                onDelete={deletePresentation}
                onChangeField={selectPresentation}
                control={formMethods.control}
                name='presentation'
              />
            </Col>
            <Col>
              <h3 style={{ color: '#EAEAEA', fontSize: 32 }}>
                {`${t('common:titles.AccountCaptures')} *`}
              </h3>
              {inputs.socialMediaHandles.map((field: any, i: any) => (
                <div key={i}>
                  <Col style={{ paddingBottom: 32, paddingTop: 32 }}>
                    {inputs.socialMediaHandles.find(
                      (item: any) => item.socialMedia === field.socialMedia
                    ) && (
                      <img
                        src={field.socialMedia + '.png'}
                        style={{
                          height: 41,
                          width: 41,
                          borderRadius: 10,
                          objectFit: 'cover',
                        }}
                      />
                    )}
                    <span style={{ color: '#EAEAEA', paddingLeft: 10, fontSize: 24 }}>
                      {field.handle}
                    </span>
                  </Col>
                  <AddImages
                    deepSetName={`socialMediaHandles.${i}.images`}
                    name={`socialMediaHandles.${i}.images`}
                    images={field.images}
                    onFileChange={selectImages}
                    onRemoveImage={removeImage.bind(null, i)}
                  />
                </div>
              ))}
            </Col>
          </Form>
        </FormProvider>
      </Row>
    </Container>
  );
}

export default AboutYourPeople;
