import { Colors } from 'theme/Colors';
import styled from 'styled-components';

export const GetSetTitle = styled.p`
  color: ${Colors.orange};
  font-size: 300px;
  font-weight: bold;
  padding-right: 15px;
  font-family: DIN-Condensed;
  padding-top: 30px;
  font-weight: bold;
  line-height: 80%;
  margin: 0;
  /* max-width: none; */
  text-transform: uppercase;
  @media (max-width: 1024px) {
    font-size: 200px;
  }
  @media (max-width: 768px) {
    font-size: 80px;
    margin-bottom: 16px;
    padding-top: 80px;
  }
`;
export const Description = styled.p`
  font-size: 70px;
  line-height: 80%;
  margin: 30px 0 0 0;
  max-width: none;
  font-family: DIN-Condensed;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`;
export const DocusignTitle = styled.span`
  font-size: 22px;
  line-height: 80%;
  margin: 0;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const SecondTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  max-width: none;
  margin-top: 12px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  /* margin-top: 20px; */
`;
export const List = styled.li`
  font-size: 20px;
  line-height: 140%;
  margin: 0;
  max-width: none;
  color: ${Colors.white};
`;
