import { ErrorText, InputLabel } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { InputText, InputContainer } from './FormStyled';

type Props = React.ComponentProps<typeof InputText> & {
  label?: string;
  errorText?: string;
  errorMessage?: string | null;
};

const TextField: React.FC<Props> = props => {
  const [isPhoneDelete, setIsPhoneDelete] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsPhoneDelete(false);
      } else if (window.innerWidth < 768) {
        setIsPhoneDelete(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const {
    label,
    errorText,
    value,
    onBlur,
    onFocus,
    placeholderTextColor,
    errorMessage,
    ...restOfProps
  } = props;
  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      {isPhoneDelete ? (
        <InputContainer>
          <InputText
            isRequired={!!errorText}
            {...restOfProps}
            value={value}
            onBlur={(event: any) => {
              onBlur?.(event);
            }}
            onFocus={(event: any) => {
              onFocus?.(event);
            }}
            rules={{
              required: false,
            }}
            autoCapitalize='none'
            placeholderTextColor={placeholderTextColor}
          />
        </InputContainer>
      ) : (
        <div className='d-flex align-items-end'>
          <InputText
            isRequired={!!errorText}
            {...restOfProps}
            value={value}
            onBlur={(event: any) => {
              onBlur?.(event);
            }}
            onFocus={(event: any) => {
              onFocus?.(event);
            }}
            rules={{
              required: false,
            }}
            autoCapitalize='none'
            placeholderTextColor={placeholderTextColor}
          />
        </div>
      )}
      {errorText != undefined && errorMessage && (
        <ErrorText color='red !important'>{errorMessage}</ErrorText>
      )}
    </>
  );
};

export default TextField;
