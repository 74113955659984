import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Apple, Google } from 'assets';
import { GetSetTitle, Description, SecondTitle, DocusignTitle } from './WelcomeStyled';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnboardingService } from 'services/apis';
import { t } from 'i18next';

const onBoarding = OnboardingService.getService();

const gotoAppleStore = async () => {
  window.open('https://apps.apple.com/us/app/storyteller/id1604667075', '_blank');
};
const gotoAGooglePlay = async () => {
  window.open(
    'https://play.google.com/store/apps/details?id=com.fersancapital.storyteller',
    '_blank'
  );
};
export const Welcome: React.FC = () => {
  const [isReady, setIsReady] = useState(true);
  const navigate = useNavigate();
  const routes = useLocation() as any;
  useEffect(() => {
    (async function () {
      try {
        setIsReady(false);
        if (routes.state?.membership !== 'influencer') {
          navigate('/home', { replace: true });
        }
        const status = await onBoarding.checkStatusById(routes.state.itemId);
        if (status !== 'signed') {
          navigate('/home', { replace: true });
        } else {
          setIsReady(true);
        }
      } catch (error: any) {
        toast.error(error.message);
        setIsReady(true);
      }
    })();
  }, []);
  return (
    <Container className='justify-content-center text-white'>
      {isReady && (
        <>
          <Row>
            <Col>
              <GetSetTitle>{t('common:titles.welcome')}</GetSetTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <Description>{t('common:titles.welcomeDes')}</Description>
            </Col>
          </Row>

          <Row>
            <Col style={{ marginTop: 32 }}>
              <DocusignTitle>{t('common:titles.completed')}</DocusignTitle>
              <SecondTitle>{t('common:titles.youreOnTheWaitingListForTheMobileApp')}</SecondTitle>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginTop: 16 }}>
              <img
                src={Google}
                style={{ height: 48, width: 156, cursor: 'pointer' }}
                onClick={gotoAGooglePlay}
                alt={'Google play'}
              />
              <img
                src={Apple}
                style={{ marginLeft: 20, height: 48, width: 156, cursor: 'pointer' }}
                onClick={gotoAppleStore}
                alt={'Apple store'}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
