import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const BarContainer = styled.div`
  flex: 1;
  height: 8px;
  background: #dfdfe7;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
  transition: 1s ease;
  transition-delay: 0.2s;
`;
const FillerBackground = styled.div`
  height: inherit;
  transition: width 2s ease-in-out;
  background: linear-gradient(269.9deg, #00bdff 0%, #ff8c2e 100%);
`;

type ProgressBarProps = {
  valuePercentage: number;
};

export const ProgressBarFlexible = (props: ProgressBarProps) => {
  const { valuePercentage: value } = props;
  const fillerRelativePercentage = (100 / value) * 100;
  const fillerFlex = value > 0 ? value / 100 : 0;

  return (
    <Wrapper role='progressbar' aria-valuemin={0} aria-valuemax={100} aria-valuenow={value}>
      <BarContainer style={{ flex: fillerFlex }}>
        <FillerBackground style={{ width: `${fillerRelativePercentage}%` }} />
      </BarContainer>
    </Wrapper>
  );
};
