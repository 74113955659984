import { Key, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthorization } from 'services';
import { OnboardingService } from 'services/apis';
import {
  Divider,
  HomeContainer,
  SubTitle,
  Title,
  UserType,
  Invited,
  Status,
  RowContainer,
  Button,
  TextBold,
  Input,
} from './HomeStyled';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { t } from 'i18next';

const onBoarding = OnboardingService.getService();
const StatusMessage = [
  {
    title: 'New',
    type: 'new',
  },
  {
    title: 'Signed',
    type: 'signed',
  },
  {
    title: 'Waiting For Signature',
    type: 'waitingForSignature',
  },
  {
    title: 'Not Signed',
    type: 'notSigned',
  },
  {
    title: 'In Progress',
    type: 'inProgress',
  },
  {
    title: 'Accepted',
    type: 'accepted',
  },
  {
    title: 'Rejected',
    type: 'rejected',
  },
];

const navigateOnboarding = (membership: string, status: string) => {
  let route = '';
  if (membership === 'influencer' && status === 'new') {
    route = '/information';
  }
  if (membership === 'influencer' && status === 'inProgress') {
    route = '/storyteller';
  }
  if (membership === 'influencer' && status === 'signed') {
    route = '/welcome';
  }
  if (membership === 'influencer' && (status === 'waitingForSignature' || status === 'notSigned')) {
    route = '/resign-contract';
  }
  return route;
};

export const Home = () => {
  const [getOnBoarding, setGetOnBoarding] = useState([]);
  const [showRedeemButton, setShowRedeemButton] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [showRedeem, setShowRedeem] = useState<boolean>(false);
  const { user } = useAuthorization();
  const navigate = useNavigate();
  const getFullName = `${user?.firstName} ${user?.lastName}`;

  useEffect(() => {
    (async function () {
      try {
        const getData = await onBoarding.checkOnBoarding();
        setGetOnBoarding(getData.items);
        if (getData.active && getData.active.id) {
          setShowRedeemButton(false);
          onClickNavigate(getData.active.membership, getData.active.status, getData.active.id);
        } else {
          setShowRedeemButton(true);
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    })();
  }, []);
  const onClickNavigate = (membership: string, status: string, itemId: string) => {
    const route = navigateOnboarding(membership, status);
    if (route !== '') {
      navigate(route, { replace: true, state: { membership, itemId } });
    }
  };

  const onClickCancel = () => {
    setCode('');
    setShowRedeem(false);
  };

  const onRedeemValidate = async () => {
    try {
      if (!code.length) {
        return;
      }
      await onBoarding.validateExistingUserInvite(code);
      const getData = await onBoarding.checkOnBoarding();
      setGetOnBoarding(getData.items);
      if (getData.active && getData.active.id) {
        setShowRedeemButton(false);
      } else {
        setShowRedeemButton(true);
      }
      onClickCancel();
      toast.success('The invitation code is accepted');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <HomeContainer>
      <Row>
        <Col>
          <Title>{t('common:titles.StoryTellerTitle')}</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubTitle>{getFullName}</SubTitle>
        </Col>
      </Row>
      <div className='d-flex justify-content-between align-items-center'>
        <TextBold>{'Select an invitation below to kick it off.'}</TextBold>
        <div>
          {showRedeemButton && (
            <Button
              lineHeight={20}
              bgColor={showRedeem ? '#A2B6BD' : ''}
              onClick={() => setShowRedeem(show => !show)}
            >
              {'Redeem invite'}
            </Button>
          )}
        </div>
      </div>
      {showRedeem && (
        <div className='mt-4 text-white'>
          <p style={{ fontSize: 16 }}>Enter your invite code below to redeem.</p>
          <div className='mb-5'>
            <label style={{ fontSize: 10 }}>INVITE CODE</label>
            <div>
              <Input value={code} type='text' onChange={e => setCode(e.target.value)} />
            </div>
          </div>
          <Row xs='auto' className='mb-3'>
            <Col>
              <Button bgColor='rgba(255, 255, 255, 0.1)' onClick={onClickCancel}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button onClick={onRedeemValidate}>Redeem</Button>
            </Col>
          </Row>
        </div>
      )}
      <Divider />
      <Row>
        {getOnBoarding?.map((item: any) => {
          const newStatus = item?.onboardingStatus
            ? item?.onboardingStatus === 'pending'
              ? 'signed'
              : item?.onboardingStatus
            : item?.status === 'signed'
            ? 'accepted'
            : item?.status;
          return (
            <div
              key={item._id}
              onClick={() => {
                if (item?.onboardingStatus && item?.onboardingStatus === 'rejected') return;
                onClickNavigate(item.membership.name, item.status, item._id);
              }}
            >
              <RowContainer>
                <div>
                  <Col>
                    <UserType>
                      {item?.membership?.name === 'influencer'
                        ? 'Investor-Creator'
                        : item?.membership?.display_name}
                    </UserType>
                  </Col>
                  <Col>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {item.invitedBy?.map(
                        (invited: {
                          _id: Key | null | undefined;
                          firstName: any;
                          lastName: any;
                        }) => {
                          return (
                            <Invited
                              key={invited?._id}
                            >{`Invited by: ${invited?.firstName} ${invited?.lastName}`}</Invited>
                          );
                        }
                      )}
                      {/* <Invited>{`Sponsor: ${item?.sponsor?.display_name}`}</Invited> */}
                      {item?.domain?.type ? (
                        <Invited>{`Domain Type: ${item?.domain?.type || '-'}`}</Invited>
                      ) : null}
                    </div>
                  </Col>
                </div>
                <Status status={newStatus}>
                  {StatusMessage?.find((status: any) => status?.type === newStatus)?.title}
                </Status>
              </RowContainer>
              <Divider />
            </div>
          );
        })}
      </Row>
    </HomeContainer>
  );
};
