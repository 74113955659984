import axios from 'axios';
import { response_catch } from './commonResponse';

export const notificationServie = {
  // Ask for help related to rejected handles
  askForHelp: async data => {
    const endPoint = `/notification/handles/help`;
    return axios
      .post(endPoint, data)
      .then(res => {
        return Promise.resolve(res.data);
      })
      .catch(error => {
        return response_catch(error, 'Unable to send message');
      });
  },
};
