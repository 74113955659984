import React, { useEffect, useMemo, useReducer } from 'react';
import { useAbility } from 'services';

import LocalStorageService, { AbilitiesService, UracService } from '../apis';
import IUser from './IuserContext';

const uracService = UracService.getService();
const abilitiesService = AbilitiesService.getService();
const localStorageService = LocalStorageService.getService();

type AuthContextData = {
  isAppLoading: boolean;
  signIn: (data: any) => void;
  updateUser: (data: any) => void;
  signOut: () => void;
  dispatch: () => void;
  user: null | IUser;
};

const initialState = {
  isAppLoading: true,
  user: null,
};

function reducer(prevState: any, action: any) {
  switch (action?.type) {
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        user: action.user,
        isAppLoading: false,
      };
    case 'SIGN_IN':
      return {
        ...prevState,
        user: action.user,
        isAppLoading: false,
      };
    case 'UPDATE_USER':
      return {
        ...prevState,
        user: action.user,
        isAppLoading: false,
      };
    case 'SIGN_OUT':
      return {
        ...prevState,
        user: null,
        checkStatus: null,
        isAppLoading: false,
      };
  }
}

const AuthContext = React.createContext<AuthContextData>({
  isAppLoading: true,
  user: null,
  checkStatus: null,
  signIn: () => {},
  signOut: () => {},
  dispatch: () => {},
  updateUser: () => {},
} as AuthContextData);

export const useAuthorization = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('Error');
  }
  return context;
};
export const AuthProvider: React.FC = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { ability } = useAbility();
  useEffect(() => {
    (async () => {
      let userToken;
      try {
        userToken = localStorageService.getAccessToken();
        if (userToken) {
          localStorageService.clearUserKeyACL();
          const user = await uracService.getUser();
          await abilitiesService.createUserAbilities(ability);
          dispatch({ type: 'RESTORE_TOKEN', user });
        } else {
          dispatch({ type: 'SIGN_OUT' });
        }
      } catch (e) {
        authContext.signOut();
        // dispatch({ type: 'SIGN_OUT' });
      }
    })();
  }, [ability]);

  const authContext = useMemo(
    () => ({
      signIn: (data: any) => {
        dispatch({ type: 'SIGN_IN', user: data });
      },
      signOut: () => {
        localStorageService.clearToken();
        localStorageService.clearUser();
        dispatch({ type: 'SIGN_OUT' });
      },
      updateUser: (data: any) => {
        dispatch({ type: 'UPDATE_USER', user: data });
      },
    }),
    []
  );
  return (
    <AuthContext.Provider value={{ ...state, ...authContext }}>
      {props.children}
    </AuthContext.Provider>
  );
};
