import { FormTextField } from 'components';
import { editProfileTypes, fieldsType, profileType } from '../../types';
import { resizeAndUploadToS3, deleteFromS3 } from 'helpers';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col, Button } from 'react-bootstrap';

import { FormProvider, useForm } from 'react-hook-form';
import { UracService } from 'services/apis';
import { useAbility, useAuthorization } from 'services';

import { ProfilePhotoView } from './ProfilePhoto';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
const uracService = UracService.getService();

export const EditProfile: React.FC = () => {
  const { ability } = useAbility();
  const [isLoading, setIsLoading] = useState(false);
  const { updateUser, user } = useAuthorization();
  const [image, setImage] = useState(user?.profile.image);
  const navigate = useNavigate();

  const openPicker = (e: any) => {
    const fileUrl = URL.createObjectURL(e);
    setImage(fileUrl);
  };

  const formMethods = useForm<editProfileTypes>({
    mode: 'onChange',
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      bio: user?.profile?.bio,
      image: user?.profile?.image,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      let newImage = image ? image : user?.profile.image;
      let deleteImage = false;
      if (image && image.indexOf('blob:') !== -1) {
        newImage = await resizeAndUploadToS3(newImage!);
        deleteImage = true;
      }
      const fields = { firstName: data.firstName, lastName: data.lastName } as fieldsType;
      const profile = {
        bio: data.bio,
        image: newImage,
      } as profileType;
      await uracService.updateMyProfile(fields, profile);
      const userUpdated = await uracService.getUser();
      updateUser(userUpdated);
      if (deleteImage) {
        await deleteFromS3(user?.profile.image);
      }
      setIsLoading(false);
      navigate(-1);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Container className='text-white'>
      <Row>
        <Col>
          <ProfilePhotoView image={image} onFileChange={openPicker} />
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <FormProvider {...formMethods}>
            <form>
              <Row>
                <Col>
                  <FormTextField
                    name='firstName'
                    keyboardType='default'
                    label={'First Name'}
                    rules={{
                      required: true,
                    }}
                    message={'required'}
                  />
                </Col>
              </Row>
              <Row className='mt-5'>
                <Col>
                  <FormTextField
                    name='lastName'
                    keyboardType='default'
                    label={'Last Name'}
                    rules={{
                      required: true,
                    }}
                    message={'required'}
                  />
                </Col>
              </Row>
            </form>
          </FormProvider>
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col>
          {ability.can('myProfile', 'update') && (
            <Button
              size='lg'
              variant='info'
              disabled={isLoading}
              onClick={formMethods.handleSubmit(onSubmit)}
              className='text-white'
            >
              {t('common:buttons.Update')}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};
