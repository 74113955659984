import { Colors } from 'theme/Colors';
import styled, { css } from 'styled-components';

export const MainTitle = styled.h1`
  color: #fff;
  font-weight: bold;
  font-size: 150px;
  line-height: 85%;
  text-transform: uppercase;
  font-family: DIN-Condensed;

  ${css`
    @media (max-width: 768px) {
      font-size: 80px;
    }
  `}
`;
export const SubMainTitle = styled.h3`
  font-size: 35px;
  font-weight: 300;
  line-height: 85%;
  padding-top: 30px;
  ${css`
    @media (max-width: 768px) {
      font-size: 20px;
      padding-bottom: 30px;
    }
  `}
`;
// padding: 64
export const VerificationFormBackground = styled.div`
  background-color: #e66752;
  border-radius: 12px;
  grid-column: 7/11;
  padding: 64px;
  ${css`
    @media only screen and (max-width: 768px) {
      border-radius: 0;
      padding: 20px 40px 20px 16px;
      /* height: 100vh; */
    }
  `};
`;
export const FormBackground = styled.div`
  background-color: #e66752;
  border-radius: 12px;
  grid-column: 7/11;
  padding: 20px 40px 20px 40px;

  ${css`
    @media only screen and (max-width: 768px) {
      border-radius: 0;
      padding: 20px 40px 20px 16px;
    }
  `}
`;
export const FormHeader = styled.div`
  //text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 08px;
`;
export const FormHeaderTitle = styled.span`
  font-weight: bold;
  font-size: 35px;
  /* line-height: 37px; */
  color: ${Colors.white};
  letter-spacing: -1px;
  line-height: 1.5px;
  font-family: DIN-Condensed;
  margin-top: 10px;
`;
export const FormHeaderDescription = styled.h3`
  font-size: 14px;
  text-align: start;
  color: ${Colors.white};
  margin: 0;
`;

export const FormWrapper = styled.form`
  display: block;
`;
export const PhoneContainer = styled.div`
  display: grid;
  grid-template-columns: 0fr 1fr;
  align-items: end;
`;
