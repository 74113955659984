import { Logo } from 'assets';
import styled from 'styled-components';

import { BackButton } from '../backButton';

type HeaderTypeProps = {
  goBack?: any;
};
export const Header: React.FC<HeaderTypeProps> = props => (
  <HeaderContainer>
    <img src={`${Logo}`} alt='logo' style={{ position: 'absolute', top: 0, left: 90 }} />
    {props.goBack && <BackButton onBack={props.goBack} />}
  </HeaderContainer>
);

const HeaderContainer = styled.div`
  height: 50px;
  position: relative;
`;
