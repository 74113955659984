import styled from 'styled-components';
import { Colors } from 'theme/Colors';
export const SeeMoreButton = styled.span`
  color: ${Colors.primary};
  font-size: 14px;
  font-weight: bold;
`;
export const SeeMoreDescription = styled.span`
  color: ${Colors.gray};
  font-size: 16px;
  margin-top: 8px;
`;
