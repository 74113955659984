import { t } from 'i18next';
import { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { OnboardingService } from 'services/apis';
import { deepSetArray, deepSet } from 'utils/deepSetLib';
import { EmpireDescription, MainTitle } from '../empire/EmpireStyled';
import { CardBox } from './CardComponent';
const onBoarding = OnboardingService.getService();

function LightsFire({ inputs, setFields }: any) {
  const [lightsYourFire, setLightsYourFire] = useState([]);
  const [selectedArray, setSelectedArray] = useState<any>([]);

  const handleClick = (item: any) => {
    const findItem = selectedArray.find((_item: any) => {
      return _item.title === item.title;
    });
    let cloneArray: any[];
    if (findItem) {
      cloneArray = selectedArray.filter((_item: any) => _item.title !== item.title);
    } else {
      cloneArray = [
        ...selectedArray,
        {
          title: item.title,
          detail: item.detail || item.description || '',
        },
      ];
    }
    setSelectedArray(cloneArray);
    setFields((prev: any) => {
      deepSet(prev, 'lightsYourFire', cloneArray);
      return prev;
    });
  };
  useEffect(() => {
    (async function getLightsYourFire() {
      const data = await onBoarding.getLightsFire();
      setLightsYourFire(data);
    })();
  }, []);
  const onFieldChange = useCallback(
    (event: any) => {
      const { value, name } = event.target;
      setFields((prevState: any) => {
        return deepSetArray(prevState, name, value);
      });
    },
    [setFields]
  );

  return (
    <Container>
      <Row className='text-center'>
        <Col>
          <MainTitle>{t('common:titles.LightsFireTitle')}</MainTitle>
        </Col>
      </Row>
      <Row className='text-center' style={{ paddingBottom: 64 }}>
        <Col>
          <EmpireDescription>{t('common:titles.LightsFireDescription')}</EmpireDescription>
        </Col>
      </Row>
      <Row>
        {lightsYourFire.map((item: any, i: any) => {
          return (
            <Col
              key={i}
              style={{
                paddingBottom: 31,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              md={6}
              xl={4}
              lg={6}
            >
              <CardBox
                selected={
                  inputs.lightsYourFire.find((_item: any) => _item.title === item.title) ||
                  selectedArray.find((_item: any) => item?.title === _item.title)
                    ? true
                    : false
                }
                handleClick={handleClick}
                item={item}
                index={i}
                selectedIndex={selectedArray.findIndex((_item: any) => item?.title === _item.title)}
                onFieldChange={onFieldChange}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default LightsFire;
