const { protocol } = window.location;
const config = {
  badTokenMessages: [
    'The access token provided has expired.',
    'The access token provided is invalid.',
  ],
  alreadyInvited: ['User is already in the tenant tenancy.', 'User has already been invited.'],
  noToken: ['/oauth/token'],
  apiEndpoint: protocol === 'https:' ? process.env.REACT_APP_SAFE_URL : process.env.REACT_APP_URL,
  tenant: process.env.REACT_APP_TENANT,
  layoutSafetyAndroid: 8,
};
export default config;
