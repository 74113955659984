import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_fr from './translations/fr/common.json';
import common_en from './translations/en/common.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'EN',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      EN: {
        common: common_en,
      },
      FR: {
        common: common_fr,
      },
    },
  });
