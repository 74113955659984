import axios from 'axios';
import LocalStorageService from './LocalStorageService';
import { response_catch, response_then } from './commonResponse';
import socialNetwork from './mock/socialNetwork.json';
import lightsYourFire from './mock/lightsYourFire.json';
import turnsOff from './mock/turnsOff.json';
import categories from './mock/categories.json';

const localStorageService = LocalStorageService.getService();

const OnboardingService = (function () {
  let _service = null;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _validateCodes(fields) {
    return axios
      .put(
        '/onboarding/validate/codes',
        {
          code: fields.code,
          emailCode: fields.emailCode,
          smsCode: fields.smsCode,
        },
        {
          headers: {
            skip_access_token: true,
          },
        }
      )
      .then(res => {
        if (res && res.data && res.data.result && res.data.data && res.data.data.access_token) {
          localStorageService.setToken(res.data.data);
          return Promise.resolve(true);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _validateInvite(fields) {
    return axios
      .put(
        '/onboarding/validate/invite/' + fields.code,
        {
          email: fields.email,
          firstName: fields.firstName,
          lastName: fields.lastName,
          phone: fields.phone,
        },
        {
          headers: { skip_access_token: true },
        }
      )
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(true);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _resendCode(fields) {
    return axios
      .put(
        '/onboarding/send/code/' + fields.type,
        {
          code: fields.code,
        },
        {
          headers: { skip_access_token: true },
        }
      )
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(true);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _updateRecord(fields) {
    const data = {
      content: {
        presentation: fields.presentation,
        socialMediaHandles: fields.socialMediaHandles,
        aboutYourPeople: fields.aboutYourPeople,
        lightsYourFire: fields.lightsYourFire,
        turnsYouOff: fields.turnsYouOff,
      },
    };
    if (fields.sponsors) {
      data.content.sponsors = fields.sponsors;
    }
    return axios
      .put('/onboarding/', data)
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(res.data.data);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _updateContractResend() {
    const data = {};
    return axios
      .put('/onboarding/contract/resend', data)
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(res.data);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _updateContract(fields) {
    const data = {
      legalName: fields.legalName,
      nickName: fields.nickName,
      companyName: fields.companyName,
    };
    return axios
      .put('/onboarding/contract', data)
      .then(res => {
        if (res && res.data && res.data.result) {
          return Promise.resolve(res.data);
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _checkStatus() {
    return axios
      .get('/onboarding/status')
      .then(res => {
        if (res && res.data && res.data.result) {
          return response_then(res, 'Unable to get user status');
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }
  function _checkStatusById(id) {
    return axios
      .get('/onboarding/status/' + id)
      .then(res => {
        if (res && res.data && res.data.result) {
          return response_then(res, 'Unable to get user status');
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _checkOnBoarding() {
    return axios
      .get('/onboarding/my/search')
      .then(res => {
        if (res && res.data && res.data.result) {
          return response_then(res, 'Unable to get user onboarding status');
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  async function _getFinance() {
    return axios
      .get('/onboarding/finance/potential')
      .then(res => {
        if (res && res.data && res.data.result) {
          return response_then(res, 'Unable to get potential finance');
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  async function _validateExistingUserInvite(code) {
    return axios
      .put(`/onboarding/validate/existing/user/invite/${code}`)
      .then(res => {
        if (res && res.data && res.data.result) {
          return response_then(res, 'The invitation code could not be validated');
        } else {
          return response_catch(new Error('Unable to proceed please try again later'));
        }
      })
      .catch(error => {
        return response_catch(error, 'Unable to proceed please try again later');
      });
  }

  function _getUserACL(extKey) {
    //TODO: check cache
    let cacheIndex = '0000000000000000';
    if (extKey) {
      cacheIndex = extKey.substr(extKey.length - 16);
    }
    let acl_cached = localStorageService.getUserKeyACL(cacheIndex);
    if (acl_cached) {
      return Promise.resolve(acl_cached);
    }
    let opt = null;
    if (extKey) {
      opt = {
        headers: { key: extKey },
      };
    }
    return axios
      .get('/soajs/acl', opt)
      .then(res => {
        if (res && res.data) {
          //TODO: cache
          localStorageService.setUserKeyACL(res.data, cacheIndex);
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(new Error('Unable to get ACL please try again later'));
        }
      })
      .catch(() => {
        return Promise.reject(new Error('Unable to get ACL please try again later'));
      });
  }

  async function _getSocialOptions() {
    return await Promise.resolve(socialNetwork);
  }
  async function _getLightsFire() {
    return await Promise.resolve(lightsYourFire);
  }
  async function _getTurnsOff() {
    return await Promise.resolve(turnsOff);
  }
  async function _getCategories() {
    return await Promise.resolve(categories);
  }

  return {
    getService: _getService,
    resendCode: _resendCode,
    validateCodes: _validateCodes,
    checkStatus: _checkStatus,
    checkStatusById: _checkStatusById,
    getUserACL: _getUserACL,
    validateInvite: _validateInvite,
    updateRecord: _updateRecord,
    getFinance: _getFinance,
    getSocialOptions: _getSocialOptions,
    getCategories: _getCategories,
    updateContract: _updateContract,
    updateContractResend: _updateContractResend,
    getLightsFire: _getLightsFire,
    getTurnsOff: _getTurnsOff,
    checkOnBoarding: _checkOnBoarding,
    validateExistingUserInvite: _validateExistingUserInvite,
  };
})();
export default OnboardingService;
