import { AwsService } from 'services/apis';

const awsService = AwsService.getService();

const resizeAndUploadToS3 = async (localUri: string): Promise<string> => {
  try {
    const upload = await awsService.getBlob(localUri);
    const s3_url = await awsService.getSignatureS3({ contentType: upload.type });
    if (s3_url && s3_url.signedUrl) {
      await awsService.uploadFileToS3SignedUrl({ signedUrl: s3_url.signedUrl, file: upload });
      const fileUrl: string = s3_url.signedUrl.split('?')[0];
      return Promise.resolve(fileUrl);
    } else {
      return Promise.reject(new Error('Unable to upload the image.'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default resizeAndUploadToS3;
