import styled from 'styled-components';
import { Colors } from 'theme/Colors';

export const ResendContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 40px;
`;

export const ResendTitle = styled.span<{ fontSize?: number; color?: string }>`
  font-size: ${props => props.fontSize || 16}px;
  color: ${props => props.color || Colors.white};
  margin-right: 5px;
  cursor: pointer;
`;
