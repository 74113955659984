import { Colors } from 'theme/Colors';
import styled from 'styled-components';

export const Textarea = styled.textarea`
  color: ${Colors.white};
  background-color: transparent;
  outline: none;
  border-radius: 4px;
  padding-block: 16px;
  padding-inline: 24px;
  font-size: 16px;
  border: 1px solid ${Colors.white};
  &::placeholder {
    color: #959393;
    opacity: 0.5;
  }
`;
