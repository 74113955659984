import React from 'react';
import styled, { css } from 'styled-components';

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  cursor: pointer;
  transform: translate(0%);
  transition: 0.3s ease-in-out;
  ${css`
    &:hover {
      position: fixed;
      transform: translate(-10%, 0%);
      transition: 0.3s ease-in-out;
    }
  `}
`;
type BackButtonTypeProp = {
  onBack: () => void;
};
export const BackButton: React.FC<BackButtonTypeProp> = props => {
  return (
    <BackButtonContainer onClick={props.onBack}>
      <svg
        width='60'
        height='50'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z' fill='white' />
      </svg>
    </BackButtonContainer>
  );
};
