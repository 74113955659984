import { Route, Routes } from 'react-router-dom';
import { Home } from 'views/home/Home';
import Information from 'views/onBoarding/influencer/information/index';
import { LoginByPhoneScreen } from 'views/urac';
import { EditProfile, ProfileScreen } from 'views/urac/account';
import { JoinByMobileScreen } from 'views/urac/joinByMobile/JoinByMobileScreen';
import { VerificationPhoneScreen } from 'views/urac/loginByPhone/verification/VerificationPhoneScreen';

import { ProtectedRoutes, UnprotectedRoutes } from './index';
import VerificationJoinScreen from 'views/urac/verificationJoin/VerificationJoinScreen';
import { StoryTellerScreen } from 'views/onBoarding/influencer/storyteller';
import { Welcome } from 'views/onBoarding/influencer/welcome';
import { ResignContract } from 'views/onBoarding/influencer/reSignContract';
import { SignContract } from 'views/onBoarding/influencer/signContract/SignContract';
import { HandleConflict } from 'views/onBoarding/influencer/handleConflict';
import { GetInTouch } from 'views/onBoarding/influencer/GetInTouch';

export const Routers = () => {
  return (
    <Routes>
      <Route element={<UnprotectedRoutes />}>
        <Route path='/' element={<LoginByPhoneScreen />} />
        <Route path='/verification' element={<VerificationPhoneScreen />} />
        <Route path='/join' element={<JoinByMobileScreen />} />
        <Route path='/validate/join' element={<VerificationJoinScreen />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/home' element={<Home />} />
        <Route path='/profile' element={<ProfileScreen />} />
        <Route path='/edit/profile' element={<EditProfile />} />
        <Route path='/information' element={<Information />} />
        <Route path='/storyteller' element={<StoryTellerScreen />} />
        <Route path='/sign-contract' element={<SignContract />} />
        <Route path='/welcome' element={<Welcome />} />
        <Route path='/resign-contract' element={<ResignContract />} />
        <Route path='/handle-conflict' element={<HandleConflict />} />
        <Route path='/GetInTouch' element={<GetInTouch />} />
      </Route>
    </Routes>
  );
};
