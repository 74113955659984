import React from 'react';
import { Ability } from '@casl/ability';
import { AbilityContext } from './Can';

const ability = new Ability();

export const useAbility = () => {
  const context = React.useContext(AbilityContext);
  if (!context) {
    throw new Error('Error');
  }
  return context;
};

export const AbilityProvider: React.FC = props => {
  return <AbilityContext.Provider value={{ ability }}>{props.children}</AbilityContext.Provider>;
};
