import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import TextField from './TextField';
import { InputContainer, PhoneInputs } from './FormStyled';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import './style.css';

type Props = React.ComponentProps<typeof TextField> & {
  name: string;
  rules: RegisterOptions;
  message?: string;
  select?: boolean;
  isPhone?: boolean;
  isNumber?: boolean;
  isDate?: boolean;
  isTextArea?: boolean;
};
const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  background-color: transparent;
  outline: 0;
  border-style: solid;
  border-bottom-color: white;
  color: white;
  border-width: 0 0 1px;
  border-radius: 0;
  padding-bottom: 7px;
`;
const TextArea = styled.textarea`
  font-size: 16px;
  color: #fff !important;
  background-color: transparent;
  border-radius: 4px;
  width: 255px;
  height: 117px;
  padding: 10px;
  line-height: 22.4px;
`;

const FormTextField: React.FC<Props> = props => {
  const {
    name,
    rules,
    message,
    select,
    isPhone,
    isNumber,
    isDate,
    onFieldChange,
    isTextArea,
    ErrorBorder,
    ...restOfProps
  } = props;
  const { control, formState } = useFormContext();

  const customStyles = {
    container: (provided: any) => ({ ...provided }),
    valueContainer: (provided: any) => ({ ...provided, input: { height: 0 }, padding: 0 }),
    placeholder: (provided: any) => ({ ...provided, margin: 0 }),

    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      color: 'white',
      background: state?.isSelected ? 'gray' : 'black',

      padding: 10,
      textAlign: 'left',
      '&:hover': {
        color: 'black',
        background: 'white',
      },
    }),
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      // none of react-select's styles are passed to <Control />
      backgroundColor: 'transparent',
      display: 'flex',
      outline: 0,
      // marginLeft: 9,
      borderStyle: 'solid',
      color: 'white',
      borderWidth: '0 0 1px',
      borderRadius: 0,
      // marginTop: 10,
      borderBottomColor: ErrorBorder
        ? ErrorBorder
        : state.isFocused
        ? 'white'
        : formState.errors[name]
        ? 'red'
        : 'white',
      textAlign: 'left',
      '&:hover': {
        borderBottomColor: state.isFocused ? 'white' : formState.errors[name] ? '#FFF38A' : 'white',
      },
      'select::-ms-expand': {
        display: 'none',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      background: 'black',
    }),
    menuList: (provided: any) => ({
      ...provided,
      background: 'black',
      zIndex: 100,
      maxHeight: '190px',
    }),
    singleValue: (provided: any, state: { isDisabled: any }) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = ' 300ms';

      return { ...provided, opacity, transition };
    },
  };

  return select ? (
    <Controller
      render={({ field: { onChange, value, onBlur } }) => (
        <InputContainer>
          <CreatableSelect
            styles={customStyles}
            onChange={event => {
              onChange(event);
              onFieldChange?.(event);
            }}
            onBlur={onBlur}
            value={value}
            errorText={formState.errors[name]}
            openMenuOnFocus
            isSearchable={false}
            {...restOfProps}
          />
        </InputContainer>
      )}
      name={name}
      control={control}
      rules={rules}
    />
  ) : isPhone ? (
    <Controller
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <PhoneInputs
          placeholder={'+xxx'}
          country={'us'}
          countryCodeEditable={false}
          inputStyle={{
            height: 30,
            width: 100,
            outline: 0,
            backgroundColor: 'transparent',
            fontSize: 18,
            borderStyle: 'solid',
            borderBottomColor: '#FFF',
            borderWidth: '0 0 1px',
            color: '#fff',
            marginRight: 20,
            borderRadius: 0,
            paddingBottom: 16,
          }}
          buttonStyle={{
            outline: 0,
            borderStyle: 'none',
            backgroundColor: 'transparent',
            fontSize: 18,
            color: '#000',
            paddingBottom: 13,
          }}
          dropdownStyle={{
            textAlign: 'left',
          }}
          {...restOfProps}
          onBlur={onBlur}
          onChange={(event: any) => {
            onChange(event);
            onFieldChange?.(event);
          }}
          value={value || ''}
        />
      )}
      name={name}
      rules={rules}
    />
  ) : isDate ? (
    <Controller
      control={control}
      name={name}
      rules={rules} //optional
      render={({ field: { onChange, value } }) => (
        <InputContainer className='react-datepicker-wrapper'>
          <DatePickerStyled
            onChange={data => {
              onChange(data);
              onFieldChange?.(data);
            }}
            className={ErrorBorder ? 'borderBottomClass' : ''}
            maxDate={restOfProps?.maxDate}
            selected={value || ''}
            placeholderText='Select date *'
          />
        </InputContainer>
      )}
    />
  ) : isTextArea ? (
    <Controller
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <TextArea
          {...restOfProps}
          onBlur={onBlur}
          onChange={(event: any) => {
            onChange(event);
            onFieldChange?.(event);
          }}
          name={name}
          value={value || ''}
        />
      )}
      name={name}
      rules={rules}
    />
  ) : (
    <Controller
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <TextField
          {...restOfProps}
          errorText={formState.errors[name]}
          onBlur={onBlur}
          onChange={(event: any) => {
            if (isNumber) {
              if (/^[0-9]+$/g.test(event.target.value) || !event.target.value) {
                onChange(event);
                onFieldChange?.(event);
              }
            } else {
              onChange(event);
              onFieldChange?.(event);
            }
          }}
          value={(restOfProps?.value || value) ?? ''}
          errorMessage={message ? message : null}
          autoFocus={formState.isSubmitted && !formState.touchedFields[name]}
          autoCapitalize='none'
          name={name}
        />
      )}
      name={name}
      rules={rules}
    />
  );
};
export default FormTextField;
