import styled, { css } from 'styled-components';
import { Colors } from 'theme/Colors';
import { IoCloseOutline } from 'react-icons/io5';

export const MainTitle = styled.h1`
  font-size: 77px;
  line-height: 85%;
  font-weight: bold;
  font-family: DIN-Condensed;
  ${css`
    @media (max-width: 768px) {
      font-size: 50px;
    }
  `}
`;
export const EmpireDescription = styled.span`
  font-size: 24px;
  line-height: 140%;
  ${css`
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `}
`;
export const ProfilePhoto = styled.img`
  height: 224px;
  object-fit: cover;
  margin: 8px;
  width: 200px;
`;
export const EditProfileButton = styled.div`
  cursor: pointer;
`;
export const EditProfileButtonIcon = styled(IoCloseOutline)`
  margin: 0;
  position: absolute;
  height: 27px;
  width: 27px;
  border-radius: 20px;
  right: 22px;
  top: 14px;
  background-color: ${Colors.black};
`;
export const ProfileWrapper = styled.div`
  position: relative;
  display: flex;
  border-radius: 2px;
  margin-bottom: 8px;
  box-sizing: border-box;
`;
export const AddImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;
