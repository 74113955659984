import { Row, Col, Container } from 'react-bootstrap';
import { Heading, Heading2, NormalText } from './handleConflict.style';
import { t } from 'i18next';
import XIcon from 'assets/svg/XIcon.svg';
import VerifiedCheckIcon from 'assets/svg/VerifiedCheckIcon.svg';
import { Colors } from 'theme/Colors';
import { Button } from 'views/home/HomeStyled';
import socialNetwork from 'services/apis/mock/socialNetwork.json';
import { useLocation, useNavigate } from 'react-router-dom';

type Social = {
  category: string;
  date: number;
  followers: number;
  handle: string;
  socialMedia: string;
};

export const HandleConflict = () => {
  const location = useLocation() as any;
  const navigate = useNavigate();

  const onFinishOnboarding = () => {
    navigate('/storyteller', {
      state: { membership: location.state?.membership, itemId: location.state?.itemId },
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Heading>{t('common:handleConflictScreen.oneMinute')}</Heading>
        </Col>
      </Row>
      <NormalText fontSize={16} className='mb-4'>
        {t('common:handleConflictScreen.description')}
      </NormalText>
      <div className='d-flex align-items-center'>
        <img src={XIcon} />
        <Heading2 className='ms-3'>{'Conflicted handles'}</Heading2>
      </div>
      <NormalText fontSize={14} className='mb-3 mt-2'>
        If you finish onboarding, these accounts <i>will not</i> be included.
      </NormalText>

      <div className='pt-3 pb-5'>
        <ul className='list-group'>
          {location.state.conflictedHandles.map((social: Social) => (
            <li key={social.handle + social.socialMedia} className='py-2'>
              <div className='d-flex align-items-center'>
                <img src={socialNetwork.find(row => row.value === social.socialMedia)?.icon} />
                <NormalText fontSize={16} className='ms-3'>
                  @{social.handle} ({social.followers?.toLocaleString()} Follower)
                </NormalText>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className='d-flex align-items-center'>
        <img src={VerifiedCheckIcon} />
        <Heading2 className='ms-3'>{'Verified handles'}</Heading2>
      </div>
      <NormalText fontSize={14} className='mb-3 mt-2'>
        These accounts <i>will be</i> included in your onboarding.
      </NormalText>

      <div className='pt-3 pb-4'>
        <ul className='list-group'>
          {location.state.verifiedHandles.map((social: Social) => (
            <li key={social.handle + social.socialMedia} className='py-2'>
              <div className='d-flex align-items-center'>
                <img src={socialNetwork.find(row => row.value === social.socialMedia)?.icon} />
                <NormalText fontSize={16} className='ms-3'>
                  @{social.handle} ({social.followers?.toLocaleString()} Follower)
                </NormalText>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <NormalText fontSize={16} className='py-4'>
        Want to be sure all your handles are added? We can help get these issues resolved quickly
        and have you on your way.
      </NormalText>
      <Heading2
        role={'button'}
        color={Colors.primary}
        className='text-uppercase'
        fontSize={22}
        onClick={() =>
          navigate('/getInTouch', {
            state: {
              handles: location.state.conflictedHandles,
              itemId: location.state.itemId,
            },
          })
        }
      >
        {'Get in touch'}
      </Heading2>

      <div className='d-flex flex-column flex-sm-row align-sm-items-center mt-5 pb-4'>
        {/* <Button
          onClick={() => navigate(-1)}
          width={260}
          className='mb-4 mb-sm-0'
          height={64}
          bgColor='rgba(255, 255, 255, 0.1)'
        >
          {'Go Back'}
        </Button> */}
        <Button onClick={() => onFinishOnboarding()} width={260} height={64} className='px-5'>
          {'Finish onboarding'}
        </Button>
      </div>
    </Container>
  );
};
