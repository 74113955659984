import LocalStorageService from './LocalStorageService';
import UracService from './UracService';
import OauthService from './OauthService';
import AwsService from './AwsService';
import AbilitiesService from './AbilitiesService';
import OnboardingService from './OnboardingService';

export {
  UracService,
  OauthService,
  LocalStorageService,
  AwsService,
  OnboardingService,
  AbilitiesService,
};

export default LocalStorageService;
