import { FormTextField } from 'components';
import { Card } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Colors } from 'theme/Colors';
export const CardBox = ({
  item,
  handleClick,
  selected,
  onFieldChange,
  index,
  selectedIndex,
}: any) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      detail: '',
    },
  });
  return (
    <Card
      style={{
        width: 330,
        height: 201,
        borderRadius: 20,
        borderWidth: 'inherit',
      }}
    >
      <Card.Body
        onClick={() => handleClick(item)}
        style={{
          borderRadius: 20,
          overflow: 'hidden',
          borderStyle: 'solid',
          borderColor: selected ? Colors.primary : Colors.white,
          borderWidth: 3,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#171717',
          cursor: 'pointer',
        }}
      >
        <Card.Title
          style={{
            paddingBottom: 16,
            fontSize: 30,
            letterSpacing: -0.35,
          }}
        >
          {item.title}
        </Card.Title>
        {item.input ? (
          <FormProvider {...formMethods}>
            <FormTextField
              rows={3}
              disabled={selectedIndex === -1}
              isTextArea
              onFieldChange={onFieldChange}
              onClick={(event: any) => event.stopPropagation()}
              name={`turnsYouOff.${selectedIndex === -1 ? index : selectedIndex}.detail`}
              placeholder={item.description}
              rules={{
                required: false,
              }}
              style={{ width: 283, height: 82 }}
            />
          </FormProvider>
        ) : (
          <Card.Title style={{ fontSize: 20, textAlign: 'center' }}>{item.description}</Card.Title>
        )}
      </Card.Body>
    </Card>
  );
};
