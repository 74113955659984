import axios from 'axios';
import { response_catch, response_then } from './commonResponse';

const AwsService = (function () {
  let _service = null;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  async function _getBlob(fileUri) {
    try {
      const response = await fetch(fileUri);
      try {
        const myBlob = await response.blob();
        return await Promise.resolve(myBlob);
      } catch (error) {
        return await response_catch(error);
      }
    } catch (error_2) {
      return await response_catch(error_2);
    }
  }

  async function _getSignatureS3({ bucketFolder = 'profile', contentType }) {
    const url = '/aws/s3/signed/url';
    let config = {
      params: {
        path: bucketFolder,
        contentType,
      },
    };
    try {
      const res = await axios.get(url, config);
      return await response_then(res);
    } catch (error) {
      return await response_catch(error);
    }
  }

  async function _deleteS3({ filePath }) {
    const url = '/aws/s3';
    let config = {
      params: { id: filePath },
    };
    try {
      const res = await axios.delete(url, config);
      return await response_then(res);
    } catch (error) {
      return await response_catch(error);
    }
  }

  async function _uploadFileToS3SignedUrl({ signedUrl, file }) {
    const opt = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    };
    try {
      return await fetch(signedUrl, opt);
    } catch (error) {
      return await response_catch(error);
    }
  }

  return {
    getService: _getService,
    getBlob: _getBlob,
    getSignatureS3: _getSignatureS3,
    deleteS3: _deleteS3,
    uploadFileToS3SignedUrl: _uploadFileToS3SignedUrl,
  };
})();
export default AwsService;
