import axios from 'axios';
import LocalStorageService from './index';
import Config from '../../utils/config';

const localStorageService = LocalStorageService.getService();

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axios.interceptors.request.use(
  async config => {
    let key = Config.tenant;
    let apiEndpoint = Config.apiEndpoint;
    const access_token = localStorageService.getAccessToken();
    let url = config.url;
    console.log(url);
    if (url.indexOf('?') !== -1) {
      url = url.substr(0, url.indexOf('?'));
    }
    if (url.indexOf('://') !== -1) {
      url = url.substr(url.indexOf('://') + 3);
      url = url.substr(url.indexOf('/'));
    } else {
      config.url = apiEndpoint + config.url;
    }
    if (
      url !== '' &&
      (Config.noToken.includes(url) || !access_token || config.headers['skip_access_token'])
    ) {
      if (config.headers['skip_access_token']) {
        delete config.headers['skip_access_token'];
      }
      if (!config.headers['key']) {
        config.headers['key'] = key;
      }
    } else {
      if (access_token) {
        config.headers['access_token'] = access_token;
      }
      if (!config.headers['key']) {
        config.headers['key'] = key;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error && error.response && error.config) {
      let url = Config.apiEndpoint;
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.codes &&
        error.response.data.errors.codes[0] === 401 &&
        Config.badTokenMessages.includes(error.response.data.errors.details[0].message)
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              return axios(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const refreshToken = await localStorageService.getRefreshToken();
        return new Promise(function (resolve, reject) {
          axios
            .post(url + '/oauth/token', {
              refresh_token: refreshToken,
              grant_type: 'refresh_token',
            })
            .then(async res => {
              if (res && res.data && res.data.access_token) {
                await localStorageService.setToken(res.data);
                processQueue(null, res.data.access_token);
                resolve(axios(originalRequest));
              }
            })
            .catch(err => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }
    return Promise.reject(error);
  }
);
