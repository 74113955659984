import { t } from 'i18next';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FormTextField } from 'components';
import { useAbility, useAuthorization } from 'services';
import LocalStorageService, { AbilitiesService, OauthService, UracService } from 'services/apis';

import { VerificationPhoneTypeProp } from '../../types';
import { FormHeaderDescription, LogInByMobileTitle } from '../LoginByPhoneStyled';
import {
  FormHeaderTitle,
  SubMainTitle,
  VerificationFormBackground,
} from 'views/urac/joinByMobile/JoinStyled';

const uracService = UracService.getService();
const abilitiesService = AbilitiesService.getService();
const localStorageService = LocalStorageService.getService();
const oauthService = OauthService.getService();

export const VerificationPhoneScreen = () => {
  const { ability } = useAbility();
  const { signIn } = useAuthorization();
  const [isLoading, setIsLoading] = useState(false);
  const concatNumber = localStorage.getItem('concatNumber') || '';

  const navigate = useNavigate();
  const formMethods = useForm<VerificationPhoneTypeProp>({
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });

  const sendCode = async () => {
    try {
      setIsLoading(true);
      await oauthService.loginByPhone(concatNumber);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: VerificationPhoneTypeProp) => {
    try {
      setIsLoading(true);
      await oauthService.loginByPhoneValidate(data.code, concatNumber);
      localStorage.removeItem('concatNumber');
      localStorageService.clearUserKeyACL();
      const user = await uracService.getUser();
      await abilitiesService.createUserAbilities(ability);
      signIn(user);
      setIsLoading(false);
      navigate('/home', { replace: true });
    } catch (error: any) {
      toast.error(error.message);
      formMethods.reset();
      setIsLoading(false);
    }
  };
  const onBack = () => {
    navigate(-1);
  };
  return (
    <Container className='text-white'>
      <Row>
        <Col>
          <LogInByMobileTitle>{t('common:titles.goodToSeeYou')}</LogInByMobileTitle>
          <SubMainTitle>{t('common:titles.WelcomeBack')}</SubMainTitle>
        </Col>
        <Col lg={6} style={{ paddingLeft: '0', paddingRight: '0' }}>
          <VerificationFormBackground>
            <FormHeaderTitle> {t('common:titles.CheckYourPhone')}</FormHeaderTitle>
            <FormHeaderDescription style={{ marginTop: 10, marginBottom: 32 }}>
              {`${t('common:titles.CheckPhoneDescription')} to ${concatNumber}. ${t(
                'common:titles.CheckPhoneDescription2'
              )}`}
              <span style={{ fontWeight: 'bold' }} onClick={sendCode}>
                {` ${t('common:buttons.Resend')}`}
              </span>
              <span>{` ${t('common:titles.ItNow')}`}</span>
            </FormHeaderDescription>
            <Row>
              <Col>
                <FormProvider {...formMethods}>
                  <Form>
                    <FormTextField
                      name={'code'}
                      keyboardType={'numeric'}
                      placeholder={`* ${t('common:titles.SmsCode')}`}
                      placeholderTextColor={'#fff'}
                      rules={{
                        required: true,
                        pattern: /^[0-9]{6}$/,
                      }}
                      message={'Wrong Code'}
                    />
                  </Form>
                </FormProvider>
              </Col>
            </Row>
            <Row className='text-center' style={{ marginTop: 48 }}>
              <Col>
                <Button
                  size='lg'
                  variant='info'
                  disabled={isLoading}
                  onClick={formMethods.handleSubmit(onSubmit)}
                  className='text-white'
                  style={{
                    width: 200,
                    borderWidth: 2,
                    marginBottom: 30,
                    borderColor: '#fff',
                    borderStyle: 'solid',
                  }}
                >
                  {t('common:buttons.ImMe')}
                </Button>
              </Col>
              <Col>
                <Button
                  size='lg'
                  onClick={onBack}
                  className='text-white'
                  style={{
                    width: 200,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                  }}
                >
                  {t('common:buttons.GoBack').toUpperCase()}
                </Button>
              </Col>
            </Row>
          </VerificationFormBackground>
        </Col>
      </Row>
    </Container>
  );
};
