import styled, { css } from 'styled-components';
import { Colors } from 'theme/Colors';

export const MainTitle = styled.span`
  font-size: 99px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: DIN-Condensed;
  line-height: 100%;
  @media (max-width: 768px) {
    font-size: 80px;
  }
`;
export const Description = styled.span`
  font-size: 21px;
  font-weight: normal;
  line-height: 140%;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const NumberTextComponent = styled.span`
  display: flex;
  color: ${Colors.orange};
  font-size: 270px;
  font-weight: bold;
  line-height: 105%;
  font-family: DIN-Condensed;
  ${css`
    @media (max-width: 991px) {
      font-size: 150px;
    }
  `}
`;
export const UsdTextComponent = styled.svg`
  color: ${Colors.orange};
  width: 120px;
  height: 250px;
  font-weight: normal;
  font-family: Influence;
  ${css`
    @media (max-width: 991px) {
      width: 75px;
      height: 140px;
    }
  `}
`;
export const BenefitsTitle = styled.span`
  font-size: 36px;
  font-weight: bold;
  line-height: 140%;
`;
export const BenefitsDescription = styled.span`
  font-size: 22px;
  font-weight: bold;
`;
export const List = styled.li`
  font-size: 16px;
  line-height: 140%;
`;
