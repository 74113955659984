import styled, { css } from 'styled-components';
import { Colors } from 'theme/Colors';

export const LogInByMobileTitle = styled.span`
  font-size: 144px;
  font-weight: bold;
  line-height: 85%;
  text-transform: uppercase;
  font-family: DIN-Condensed;
  width: 543px;
  @media (max-width: 768px) {
    font-size: 80px;
    line-height: 80px;
  }
`;
export const LoginFormBackground = styled.div`
  background-color: #e66752;
  border-radius: 12px;
  grid-column: 7/11;
  padding: 20px 40px 20px 40px;
  ${css`
    @media only screen and (max-width: 768px) {
      border-radius: 0;
      padding: 20px 40px 20px 16px;
      height: 100vh;
    }
  `}
`;
export const LogInDescriptions = styled.span`
  font-size: 24px;
  color: ${Colors.lightGray};
  line-height: 22px;
`;
export const FormHeaderDescription = styled.h3`
  text-align: start;
  color: ${Colors.white};
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: 140%;
  width: 350px;
`;
export const FormWrapper = styled.form`
  justify-content: center;
  align-items: end;
  display: flex;
  flex-direction: row;
  padding-top: 31px;
  padding-bottom: 31px;
`;
export const JoinTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${Colors.white};
`;
