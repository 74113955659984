import styled, { css } from 'styled-components';
import { Colors } from 'theme/Colors';

export const ValidateContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const FormWrapper = styled.form`
  padding-top: 31px;
  padding-bottom: 31px;
`;
export const MainTitle = styled.h1`
  color: #fff;
  font-weight: bold;
  font-size: 150px;
  line-height: 85%;
  //display: flex;
  //align-items: center;
  //text-align: start;
  text-transform: uppercase;
  font-family: DIN-Condensed;

  ${css`
    @media (max-width: 768px) {
      font-size: 50px;
    }
  `}
`;
export const SubMainTitle = styled.h3`
  //color: #c2c2c2;
  font-size: 35px;
  line-height: 85%;
  //text-align: start;
  padding-top: 30px;
  ${css`
    @media (max-width: 768px) {
      font-size: 20px;
    }
  `}
`;
export const FormBackground = styled.div`
  background-color: #e66752;
  border-radius: 12px;
  grid-column: 7/11;
  padding: 20px 40px 20px 40px;
  ${css`
    @media only screen and (max-width: 660px) {
      //grid-column: 1 / span 11;
      border-radius: 0;
    }
  `}
`;
export const FormHeader = styled.div`
  padding-top: 64px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 08px;
`;
export const FormHeaderTitle = styled.span`
  font-weight: bold;
  font-size: 35px;
  /* line-height: 37px; */
  color: ${Colors.white};
  letter-spacing: -1px;
  line-height: 1.5px;
  font-family: DIN-Condensed;
  margin-top: 10px;
`;
export const FormHeaderDescription = styled.h3`
  font-size: 14px;
  padding-bottom: 32px;
  text-align: start;
  color: ${Colors.white};
  margin: 0;
`;
