import React from 'react';
import styled from 'styled-components';
import { Person } from 'assets';

export const UserAvatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 80px;
  margin-bottom: 20px;
  align-self: center;
  object-fit: cover;
`;
type ProfileImageTypeProps = {
  avatar: string | undefined;
};
export const ProfileImage: React.FC<ProfileImageTypeProps> = props => (
  <UserAvatar src={props.avatar ? props.avatar : Person} alt={'user avatar'} />
);
