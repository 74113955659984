export const socialMediaHandles = {
  socialMedia: null,
  category: null,
  handle: null,
  followers: null,
  date: null,
  images: [],
};

export type ISocial = keyof typeof socialMediaHandles;

export const sponsors = {
  name: null,
  type: null,
  description: null,
  clause: null,
};

export type ISponsors = keyof typeof sponsors;
export const items = {
  title: null,
  detail: null,
};
export const initModel = {
  socialMediaHandles: [
    {
      ...socialMediaHandles,
    },
  ],
  presentation: null,
  sponsors: [
    {
      ...sponsors,
    },
  ],
  turnsYouOff: [{ ...items }],
  lightsYourFire: [{ ...items }],
};
