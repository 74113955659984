import styled, { css } from 'styled-components';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 30px;
    justify-content: center;
  }
`;

export const LabelContainer = styled.label`
  font-size: 14px;
  font-weight: 400;
`;

export const MainTitle = styled.h1`
  font-size: 80px;
  font-weight: bold;
  font-family: DIN-Condensed;
  ${css`
    @media (max-width: 768px) {
      font-size: 50px;
    }
  `}
`;
export const EmpireDescription = styled.span`
  font-size: 24px;
  line-height: 140%;
  ${css`
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `}
`;
export const EmpireAsOfWrapper = styled.div`
  display: flex;
  align-items: end;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
