import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Person } from 'assets';
import { Colors } from 'theme/Colors';
import { Row, Col } from 'react-bootstrap';

import { EditProfileButton, EditProfileButtonIcon, ProfilePhoto } from './EditProfileStyled';

type EditProfileImageTypeProp = {
  color?: string;
  image?: any;
  onFileChange?: any;
};
export const ProfilePhotoView: React.FC<EditProfileImageTypeProp> = ({ image, onFileChange }) => {
  const onDropAccepted = async (files: any) => {
    const [file] = files;
    onFileChange(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    multiple: false,
  });
  return (
    <Row>
      <Col>
        <ProfilePhoto src={image ? image : Person} />
        <EditProfileButton {...getRootProps()}>
          <EditProfileButtonIcon name='ios-add' size={40} color={Colors.placeholder} />
          <input {...getInputProps()} />
        </EditProfileButton>
      </Col>
    </Row>
  );
};
