import React from 'react';
import { Colors } from 'theme/Colors';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'DIN-Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
  }
  html {
    /* overflow: hidden; */
    height: 100%;
    overflow: auto;
  }
  body {
    overflow-x: hidden;

    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: #202020;
  }
  p {
    max-width: 600px;
    color: ${Colors.white};
    text-align: left;
    font-size: 14px;
    line-height: 140%;
    margin-top:40px
  }
  a{
    color: white;
    text-decoration: none;
    font-weight: bold
  }
`;
export const InputLabelStyled = styled.span`
  color: ${Colors.inputLabel};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;
export const InputLabel: React.FC = props => (
  <div style={{ flexDirection: 'row', alignItems: 'center' }}>
    <InputLabelStyled {...props} />
  </div>
);
export const ErrorText = styled.span<{ color?: string; ml?: number }>`
  color: ${Colors.error};
  font-size: 12px;
  margin-left: ${props => props.ml || 0}px;
`;
export const Container = styled.div`
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const FooterContainer = styled.div`
  overflow: auto;
  height: inherit;

  margin-top: 1rem;
  padding: 1rem;
  background-color: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
  flex: 1;
`;

export const Footer: React.FC = props => (
  <>
    <div style={{ display: 'block', width: '100%' }}>
      <FooterContainer>{props.children}</FooterContainer>
    </div>
  </>
);
const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  left: 60px; */
  cursor: pointer;
`;
const DeleteButtonTitle = styled.div`
  color: #00bdff;
  font-size: 20px;
  font-family: DIN-Condensed;
  /* line-height: 24px; */
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
`;
export const DeleteButton: React.FC<{ onClick: () => void }> = props => (
  <DeleteButtonContainer onClick={props.onClick}>
    <DeleteButtonTitle> DELETE </DeleteButtonTitle>
  </DeleteButtonContainer>
);
export const Divider = styled.div`
  height: 1px;
  background-color: ${Colors.white};
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
`;
