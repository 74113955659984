import React from 'react';
import { useAuthorization } from 'services';
import { Container, Row, Col, Button } from 'react-bootstrap';

import { ProfileImage } from './ProfileStyled';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

export const ProfileScreen: React.FC = () => {
  const { user } = useAuthorization();
  const navigate = useNavigate();

  return (
    <Container className='text-white'>
      <Row>
        <Col xs={2}>
          <ProfileImage avatar={user?.profile?.image} />
        </Col>
        <Col className='align-self-center'>
          <h1>{`${user?.firstName} ${user?.lastName}`}</h1>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <Button
            size='lg'
            variant='info'
            onClick={() => navigate('/edit/profile')}
            className='text-white'
          >
            {t('common:buttons.EditProfile')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default ProfileScreen;
