import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FormTextField } from 'components';
import { OnboardingService } from 'services/apis';

import { LetsSignTitle, LetsSignDescription } from './SignStyled';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const onBoarding = OnboardingService.getService();

export const SignContract = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(true);
  const navigate = useNavigate();
  const routes = useLocation() as any;

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      legalName: '',
      nickName: '',
      companyName: '',
    },
  });
  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await onBoarding.updateContract(data).then((res: any) => window.open(res.data.url, '_self'));
    } catch (error: any) {
      toast.error(error.message);
      formMethods.reset();
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async function () {
      try {
        setIsReady(false);
        if (routes.state?.membership !== 'influencer') {
          navigate('/home', { replace: true });
        }
        const status = await onBoarding.checkStatusById(routes.state.itemId);
        if (status === 'signed') {
          navigate('/welcome', {
            state: { membership: routes.state?.membership, itemId: routes.state?.itemId },
          });
        } else if (status !== 'inProgress') {
          navigate('/home', { replace: true });
        } else {
          setIsReady(true);
        }
      } catch (error: any) {
        toast.error(error.message);
        setIsReady(true);
      }
    })();
  }, []);
  return (
    <Container className='text-center text-white'>
      {isReady && (
        <>
          <Row style={{ marginBottom: 16 }}>
            <Col>
              <LetsSignTitle>Let’s sign</LetsSignTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <LetsSignDescription>
                {t('common:titles.SignContractDescription')}
              </LetsSignDescription>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col>
              <FormProvider {...formMethods}>
                <Form style={{ maxWidth: 350, margin: '0 auto', textAlign: 'left' }}>
                  <FormTextField
                    name='legalName'
                    placeholder='* Legal Name'
                    rules={{
                      required: true,
                    }}
                    message={'Required'}
                  />
                  <FormTextField
                    name='nickName'
                    placeholder='Nick Name'
                    rules={{
                      required: false,
                    }}
                    style={{ marginTop: 50 }}
                  />
                  <FormTextField
                    name='companyName'
                    placeholder='Your company name (if applicable)'
                    rules={{
                      required: false,
                    }}
                    style={{ marginTop: 50 }}
                  />
                </Form>
              </FormProvider>
            </Col>
          </Row>
          <Row className='mt-5 mb-3'>
            <Col>
              <Button
                size='lg'
                variant='info'
                className='text-white'
                disabled={isLoading}
                onClick={formMethods.handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
